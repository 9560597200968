import React, { useEffect, useState } from "react";
import { getUser, loadProfile, togglePopupRedux, toggleWalletPopupRedux, checkZRunNft } from "store/user";
import { withRouter } from "utils/withRouter";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import DiscordChatModal from "common/sections/discordChat";
import Header from "common/header";
import DetailsModalCard from "contests/components/common/detailsModalCard";
import { Accordion } from "react-bootstrap";
import mixpanel from "mixpanel-browser";
import HowItWorks from "contests/components/modals/howItWorks";
import {
  cowitzerEnter,
  getGame,
  getposition,
  loadBgGameDetail,
  loadBgGames,
  loadCowitzerLeaderBoard,
  rollDice,
  savePosition,
} from "store/game";

const cardIcon1 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/s-new-icon-1.png";
const musicIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/music-000.png";
const mutedIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/mute-icon-001.png";
const sounds = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/Sound.svg";
const musicMuted = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/Vector.svg";
const whiteIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/loot-icon.png";
const greenIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/loot-icon.png";
const pageIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/page-icon-02.svg";
const redIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/loot-icon.png";
const logo = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/burnghostHorizontal.png";
window.MESSAGE_FACTORY = function (name, data) {
  console.log("MESSAGE_FACTORY", name, data);
  return JSON.stringify({
    type: name,
    data: data,
  });
};
const BoardGame = ({
  loadProfile,
  getUser,
  togglePopupRedux,
  toggleWalletPopupRedux,
  checkZRunNft,
  loadBgGames,
  loadBgGameDetail,
  cowitzerEnter,
  loadCowitzerLeaderBoard,
  getGame,
  savePosition,
  getposition,
  rollDice,
  ...rest
}) => {
  window.GC_API = {
    UserBalance: "UserBalance",
    GameLoaded: "GameLoaded",
    GameStart: "GameStart",
    setIsWaitingForNextRound: "setIsWaitingForNextRound",
    setIsTimerRunning: "setIsTimerRunning",
    setMultiplierValue: "setMultiplierValue",
    PlaceBet: "PlaceBet",
    Multiplier: "Multiplier",
    NewBet: "NewBet",
    CashOut: "CashOut",
    Last10Rounds: "Last10Rounds",
    multiplierValue: "multiplierValue",
    startingIn: "startingIn",
    mybets: "mybets",
    winner: "winner",
    dogBet: "dogBet",
    gameState: "gameState",
    setIsMuted: "setIsMuted",
    setIsBackgroundMuted: "setIsBackgroundMuted",
    RESET: "RESET",
  };

  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const [howItWork, setHowItWork] = useState({});
  const [bgGameLoaded, setBgGameLoaded] = useState(false);
  const [music, setMusic] = useState(localStorage.getItem("musicMuted") === "true");
  const [muted, setMuted] = useState(localStorage.getItem("soundMuted") === "true");
  const [loading, setLoading] = useState(false);
  const [gameLoaded, setGameLoaded] = useState(false);

  useEffect(() => {
    const iFrame = document.getElementById("iframe-container");
    console.log("GameLoaded");
    setTimeout(() => {
      iFrame.contentWindow.postMessage(
        window.MESSAGE_FACTORY("UserBalance", {
          lootBonusCoins: getUser.profile.userProfile.lootBonusCoins,
        }),
        "*",
      );
    }, 1000);
  }, [gameLoaded]);

  const toggleMusic = (e) => {
    if (e) e.preventDefault();
    updateSound("music");
    setMusic(!music);
  };

  const toggleMute = (e) => {
    if (e) e.preventDefault();
    updateSound("sound");
    setMuted(!muted);
  };

  const updateSound = (type) => {
    const iFrame = document.getElementById("iframe-container");
    if (!iFrame) return;

    if (type === "music") {
      const muteStateData = {
        musicMuted: !music,
        soundsMuted: muted,
      };
      // send MuteState message to iframe using MessageFactory
      iFrame.contentWindow.postMessage(window.MESSAGE_FACTORY("MuteState", muteStateData), "*");

      localStorage.setItem("musicMuted", muteStateData.musicMuted);
    }

    if (type === "sound") {
      const muteStateData = {
        musicMuted: music,
        soundsMuted: !muted,
      };
      // send MuteState message to iframe setIsBackgroundMuted
      iFrame.contentWindow.postMessage(window.MESSAGE_FACTORY("MuteState", muteStateData), "*");

      localStorage.setItem("soundMuted", muteStateData.soundsMuted);
    }
    iFrame?.focus();
  };
  const toggleHowItWorks = (e) => {
    if (e) e.preventDefault();
    setShowHowItWorks(!showHowItWorks);
  };
  useEffect(() => {
    loadProfile((res) => {
      if (res.status === 401) window.location.href = "/contests";
    });
    const slug = rest.router.params.slug;
    loadBgGameDetail(slug, (res) => {
      setHowItWork(res.data.data);
      setBgGameLoaded(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (!bgGameLoaded) return;
    if (process.env.REACT_APP_MIXPANEL == 1) {
      mixpanel?.track("Cowitzer Viewed");
    }

    getposition((res) => {
      console.log("getpositiongetposition", res.data);
      if (res.data?.data?.positionLog) {
        const iFrame = document.getElementById("iframe-container");
        iFrame.contentWindow.postMessage(
          window.MESSAGE_FACTORY("getPosition", {
            position: res.data.data,
          }),
          "*",
        );
      }
    });

    //Loading iframe
    const iFrame = document.getElementById("iframe-container");
    iFrame.src = bgGameLoaded.url;
    window.loading = false;
    window.launched = false;

    const savePositionCall = (position) => {
      console.log("savePosition", position);
      savePosition({ position: position }, (res) => {
        console.log("savePosition", res);
      });
    };

    const rollDiceCall = () => {
      rollDice((res) => {
        console.log("rollDiceCall", res);
        // seding message to iframe
        const iFrame = document.getElementById("iframe-container");
        iFrame.contentWindow.postMessage(
          window.MESSAGE_FACTORY("getDiceRoll", {
            diceRoll: 1,
          }),
        );
      });
    };

    const listener = (event) => {
      // Your existing event listener logic
      try {
        if (typeof event.data !== "string") return;
        const ourMessage = JSON.parse(event.data);
        console.log("111ourMessageourMessage===>", ourMessage);
        if (ourMessage?.type) {
          switch (ourMessage.type) {
            case "Position":
              savePositionCall(ourMessage.data);
              break;
            case "sendDiceRoll":
              console.log("sendDiceRoll");
              rollDiceCall();
              break;
            // case window.GC_API.RESET:
            //   window.launched = false;
            //   loadProfile();
            //   loadCowitzerLeaderBoard();
            //   break;
            // case window.GC_API.MuteMusic:
            //   // Handle MuteMusic event
            //   break;
            // case window.GC_API.MuteSounds:
            //   // Handle MuteSounds event
            //   break;

            default:
            // Handle other events if needed
          }
        }
      } catch (e) {
        // Ignore exception - not a message for us and couldn't JSON parse it
        console.log(e);
      }
    };

    window.addEventListener("message", listener);
  }, [bgGameLoaded]);

  useEffect(() => {
    if (!getUser.profile.userProfile.lootBonusCoins) return;
    const iFrame = document.getElementById("iframe-container");
    iFrame.contentWindow.postMessage(
      window.MESSAGE_FACTORY("userProfile", {
        lootBonusCoins: getUser.profile.userProfile,
      }),
      "*",
    );
  }, [getUser.profile.userProfile]);

  // User Profile data
  const { cowitzerLeaderboardLoading, cowitzerLeaderboard } = getGame;
  return (
    <>
      <div className="fs-game-page-wrapper dark-bg min-vh-100">
        <div className="container-fluid px-lg-2 px-2">
          <div className="row gx-2">
            <div className="col-md-12">
              <div className="fs-game-page-holder vh-100 d-flex flex-lg-row flex-column flex-lg-nowrap">
                <div className="fs-game-page-sidebar h-100 flex-shrink-0 d-none d-lg-block hide-scrollbar">
                  <Accordion className="page-accordion" id="pageAccordation" defaultActiveKey={["0", "1", "3", "4"]}>
                    <div className="fs-game-page-sidebar-inside hide-scrollbar h-100 d-flex flex-column">
                      <div className="fs-logo-wrap d-flex flex-wrap align-items-center justify-content-between">
                        <div className="fs-logo">
                          <a href="/">
                            {" "}
                            <img src={logo} alt="" />
                          </a>
                        </div>
                        <div className="fs-button-group d-flex flex-wrap align-items-center">
                          <div className="fs-button-item">
                            <button
                              type="button"
                              className="fs-button overflow-hidden d-flex align-items-center justify-content-center"
                              onClick={toggleMusic}
                            >
                              <img src={music ? musicMuted : musicIcon} alt="music" />
                            </button>{" "}
                          </div>
                          <div className="fs-button-item">
                            <button
                              type="button"
                              className="fs-button overflow-hidden d-flex align-items-center justify-content-center"
                              onClick={toggleMute}
                            >
                              <img src={muted ? mutedIcon : sounds} alt="music" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="fs-widget-col d-none d-lg-block">
                        <CowitzerLeaderboard
                          cowitzerLeaderboardLoading={cowitzerLeaderboardLoading}
                          cowitzerLeaderboard={cowitzerLeaderboard}
                        />
                      </div> */}
                      <div className="accordion-item d-none d-lg-block">
                        <DetailsModalCard
                          title="How it Works"
                          subTitle="Learn how to play!"
                          icon={cardIcon1}
                          onClick={toggleHowItWorks}
                        />
                      </div>{" "}
                    </div>
                  </Accordion>
                </div>
                <div className="fs-game-page-main h-100 flex-grow-1 d-flex flex-column">
                  <div className="lg-none-header">
                    <Header crashGame={true} type={"crashGame"} />
                  </div>
                  <iframe
                    id="iframe-container"
                    className="iframe-container"
                    height="100%"
                    width="100%"
                    title="crash-game"
                  ></iframe>
                  <div
                    className="fs-game-page-sidebar-inside d-flex flex-column d-lg-none"
                    style={{ marginTop: "8px" }}
                  >
                    <DetailsModalCard
                      title="How it Works"
                      subTitle="Learn how to play!"
                      icon={cardIcon1}
                      onClick={toggleHowItWorks}
                    />
                    <div className="fs-widget-col">
                      <CowitzerLeaderboard
                        cowitzerLeaderboardLoading={cowitzerLeaderboardLoading}
                        cowitzerLeaderboard={cowitzerLeaderboard}
                      />
                    </div>
                  </div>
                </div>

                <div className="fs-game-page-chatroom flex-shrink-0 d-none d-xl-block">
                  <DiscordChatModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowItWorks showModal={showHowItWorks} toggleModal={toggleHowItWorks} bgGame={howItWork} />
    </>
  );
};
const CowitzerLeaderboard = ({ cowitzerLeaderboardLoading, cowitzerLeaderboard }) => {
  return (
    <>
      <div className="fs-widget-header d-flex flex-nowrap align-items-center justify-content-between">
        <div className="fs-w-button d-flex flex-wrap align-items-center flex-grow-1">
          <div className="fs-w-icon d-flex align-items-center justify-content-center flex-shrink-0">
            <img src={pageIcon} alt="" />
          </div>
          <div className="fs-w-txt text-uppercase flex-grow-1">
            <div className="nowrap-text-box w-100 h-100 d-flex align-items-center">
              <p>Leaderboard</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadProfile: (callback) => dispatch(loadProfile(callback)),
  togglePopupRedux: (payload) => dispatch(togglePopupRedux(payload)),
  toggleWalletPopupRedux: (data) => dispatch(toggleWalletPopupRedux(data)),
  checkZRunNft: (data) => dispatch(checkZRunNft(data)),
  loadBgGames: (data, callback) => dispatch(loadBgGames(data, callback)),
  loadBgGameDetail: (data, callback) => dispatch(loadBgGameDetail(data, callback)),
  cowitzerEnter: (data, callback) => dispatch(cowitzerEnter(data, callback)),
  loadCowitzerLeaderBoard: () => dispatch(loadCowitzerLeaderBoard()),
  //
  savePosition: (data, callback) => dispatch(savePosition(data, callback)),
  getposition: (callback) => dispatch(getposition(callback)),
  rollDice: (callback) => dispatch(rollDice(callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
  getGame: getGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardGame));
