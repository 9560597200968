import axios from "axios";
import * as actions from "../api";
const UNAUTHORIZED = 401;
const NOTFOUND = 404;
const UNDER_MAINTENANCE = 503;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      localStorage.removeItem("x-auth-token");
      localStorage.removeItem("x-refresh-token");
      // window.location.href = "/";
    }
    if (status === NOTFOUND) {
      //window.location.href = "/404";
    }

    //redirect to maintance page if not on maintance page
    const currentPath = window.location.pathname;
    if (status === UNDER_MAINTENANCE && currentPath !== "/maintenance") {
      window.location.href = "/maintenance";
    }
    return Promise.reject(error);
  },
);

const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);
    const { url, method, data, params, onStart, onSuccess, onError, callback = false } = action.payload;
    if (onStart) dispatch({ type: onStart });

    next(action);

    const headers = {
      "X-API-KEY": process.env.REACT_APP_APIKEY,
    };

    if (localStorage.getItem("x-auth-token")) {
      headers["Authorization"] = localStorage.getItem("x-auth-token");
    }

    try {
      const response = await axios.request({
        baseURL: process.env.REACT_APP_APIURL,
        headers,
        url,
        method,
        params,
        data,
      });
      // General
      dispatch(actions.apiCallSuccess(response.data));
      // Specific
      if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
      if (callback) callback(response);
    } catch (error) {
      if (error.response?.status === 500) {
        //window.location.href = "/500";
      } else if (error.response?.status === 404) {
        window.location.href = "/404";
      }
      // General
      dispatch(actions.apiCallFailed(error.message));
      // Specific
      if (onError) dispatch({ type: onError, payload: error });
      if (callback) callback(error.response);
    }
  };

export default api;
