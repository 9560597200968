import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import jwt from "jwt-decode";
import moment from "moment";

const slice = createSlice({
  name: "sweepstakes",
  initialState: {
    //  Instances
    instances: [],
    instanceDetails: null,
    sweepstakesLeaderBoard: [],
    myInstances: [],
    myInstancesLastKey: null,
    pastInstances: [],
    pastInstancesLastKey: null,
    pastInstancesCount: null,

    //  loading states
    loading: true,
    leaderBoardLoading: false,
    pastInstanceLoading: false,
    myInstanceLoading: false,
    loadingUpdatedInfo: false,
    lastFetchPastInstances: null,
  },

  reducers: {
    sweepsRequested: (misc, action) => {
      misc.loading = true;
    },
    sweepsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    sweepsUpdatedInfoRequested: (misc, action) => {
      misc.loadingUpdatedInfo = true;
    },
    sweepsUpdatedInfoRequestFailed: (misc, action) => {
      misc.loadingUpdatedInfo = false;
    },
    sweepsLeaderBoardRequestFailed: (misc, action) => {
      misc.leaderBoardLoading = false;
    },
    sweepsLeaderBoardRequested: (misc, action) => {
      misc.leaderBoardLoading = true;
    },
    sweepsPastInstanceRequested: (misc, action) => {
      misc.pastInstanceLoading = true;
    },
    sweepsPastInstanceRequestFailed: (misc, action) => {
      misc.pastInstanceLoading = false;
    },

    //  Instances
    instancesReceived: (misc, action) => {
      misc.instances = action.payload.data;
      misc.loading = false;
    },
    pastInstancesReceived: (misc, action) => {
      misc.pastInstances = action.payload.data;
      misc.pastInstancesLastKey = action.payload.lastKey;
      misc.pastInstancesCount = action.payload.totalCount;
      misc.pastInstanceLoading = false;
      misc.lastFetchPastInstances = Date.now();
    },
    morePastInstancesReceived: (misc, action) => {
      misc.pastInstances = [...misc.pastInstances, ...action.payload.data];
      misc.pastInstancesLastKey = action.payload.lastKey;
      misc.pastInstanceLoading = false;
      misc.lastFetchPastInstances = Date.now();
    },
    instanceDetailsReceived: (misc, action) => {
      misc.instanceDetails = action.payload.data;
      misc.loading = false;
      misc.loadingUpdatedInfo = false;
    },
    sweepstakesLeaderBoardReceived: (misc, action) => {
      misc.sweepstakesLeaderBoard = action.payload.data;
      misc.leaderBoardLoading = false;
    },

    //  My Active Instances
    myActiveSweepsRequested: (misc, action) => {
      misc.myInstanceLoading = true;
    },
    myActiveInstancesReceived: (misc, action) => {
      misc.myInstances = action.payload.data;
      misc.myInstancesLastKey = action.payload.lastKey;
      misc.myInstanceLoading = false;
    },
    moreMyActiveInstancesReceived: (misc, action) => {
      misc.myInstances = [...misc.myInstances, ...action.payload.data];
      misc.myInstancesLastKey = action.payload.lastKey;
      misc.myInstanceLoading = false;
    },
    myActiveSweepsRequestFailed: (misc, action) => {
      misc.myInstanceLoading = false;
    },
  },
});

export const {
  sweepsRequested,
  sweepsRequestFailed,
  sweepsUpdatedInfoRequested,
  sweepsUpdatedInfoRequestFailed,
  sweepsLeaderBoardRequestFailed,
  sweepsLeaderBoardRequested,
  sweepsPastInstanceRequested,
  sweepsPastInstanceRequestFailed,
  //  Instances
  instancesReceived,
  instanceDetailsReceived,
  sweepstakesLeaderBoardReceived,
  myActiveInstancesReceived,
  moreMyActiveInstancesReceived,
  pastInstancesReceived,
  morePastInstancesReceived,
  myActiveSweepsRequested,
  myActiveSweepsRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const sweepsUrl = "sweeps/";
const instanceUrl = sweepsUrl + "instance/";

//  Instances
export const loadInstances = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceUrl,
      onStart: sweepsRequested.type,
      onSuccess: instancesReceived.type,
      onError: sweepsRequestFailed.type,
      callback,
    }),
  );
};
//  Instance Details
export const loadInstanceDetails = (slug, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceUrl + slug,
      onStart: sweepsRequested.type,
      onSuccess: instanceDetailsReceived.type,
      onError: sweepsRequestFailed.type,
      callback,
    }),
  );
};
export const loadUpdatedInstanceInfo = (slug, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceUrl + slug,
      onStart: sweepsUpdatedInfoRequested.type,
      onSuccess: instanceDetailsReceived.type,
      onError: sweepsUpdatedInfoRequestFailed.type,
      callback,
    }),
  );
};
//  Enter Sweep
export const enterSweep = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: instanceUrl + "enter",
      data: data,
      callback,
    }),
  );
};
//  Sweep Leaderboard
export const loadSweepstakesLeaderBoard = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceUrl + "leaderboard/" + id,
      onStart: sweepsLeaderBoardRequested.type,
      onSuccess: sweepstakesLeaderBoardReceived.type,
      onError: sweepsLeaderBoardRequestFailed.type,
      callback,
    }),
  );
};
//  My Instances
export const loadMyInstances = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: instanceUrl + "my",
      onStart: myActiveSweepsRequested.type,
      onSuccess: params.lastKey ? moreMyActiveInstancesReceived.type : myActiveInstancesReceived.type,
      onError: myActiveSweepsRequestFailed.type,
      params,
      callback,
    }),
  );
};

//  Past Instances
export const loadPastInstances = (params, callback) => (dispatch, getState) => {
  const more = params.more;
  delete params.more;
  const { lastFetchPastInstances } = getState().entities.sweepstakes;
  const diffInMinutes = moment().diff(moment(lastFetchPastInstances), "minutes");
  if (diffInMinutes < 10 && !more) return;
  return dispatch(
    apiCallBegan({
      url: sweepsUrl + "pastInstance",
      onStart: sweepsPastInstanceRequested.type,
      onSuccess: !more ? pastInstancesReceived.type : morePastInstancesReceived.type,
      onError: sweepsLeaderBoardRequestFailed.type,
      params,
      callback,
    }),
  );
};
export const getSweepStakes = createSelector(
  (state) => state.entities.sweepstakes,
  (sweepstakes) => sweepstakes,
);
