import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import jwt from "jwt-decode";
import moment from "moment";

const slice = createSlice({
  name: "contests",
  initialState: {
    contestDetails: null,
    loadingContestDetails: false,
    activeContests: [],
    loadingStatsArcade: false,
    loadingStatsLoot: false,
    contestStatsArcade: null,
    contestStatsLoot: null,
    loading: false,
    players: [],
    myGame: null,
    players1: null,
    myGame1: null,
    question: null,
    gameAvailable: true,
    lastTriviaGame: null,
    rates: null,
    empty: 0,
    allContests: [],
    pendingContests: [],
    pendingContestsLastKey: null,
    pendingContestsCount: null,
    allContestsData: null,
    loadingContestResult: true,
    contestResult: null,
    loadingP: false,
    lastFetchPendingContests: null,
  },

  reducers: {
    contestDetailsRequested: (misc, action) => {
      misc.loadingContestDetails = true;
    },
    contestDetailsReceived: (misc, action) => {
      misc.contestDetails = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loadingContestDetails = false;
    },
    allContestReceived: (misc, action) => {
      let contestPayload = jwt(action?.payload)?.data;
      const typeOrder = { 3: 0, 1: 1, 2: 2 };
      contestPayload?.sort((a, b) => typeOrder[a.contestType] - typeOrder[b.contestType]);
      misc.allContests = contestPayload?.splice(0, 2);
      misc.allContestsData = jwt(action?.payload);
      misc.lastFetch = Date.now();
      misc.loadingContestDetails = false;
    },
    pendingContestRequested: (misc, action) => {
      misc.loadingP = true;
    },
    pendingContestReceived: (misc, action) => {
      misc.pendingContests = jwt(action?.payload)?.data;
      misc.pendingContestsLastKey = jwt(action?.payload)?.lastKey;
      misc.pendingContestsCount = jwt(action?.payload)?.totalCount;
      misc.loadingP = false;
      misc.lastFetchPendingContests = Date.now();
    },
    morePendingContestReceived: (misc, action) => {
      misc.pendingContests = [...misc.pendingContests, ...jwt(action?.payload)?.data];
      misc.pendingContestsLastKey = jwt(action?.payload)?.lastKey;
      misc.loadingP = false;
      misc.lastFetchPendingContests = Date.now();
    },
    contestDetailsRequestFailed: (misc, action) => {
      misc.loadingContestDetails = false;
      misc.contestDetails = null;
      misc.error = action.payload.message;
    },
    contestResultRequested: (misc, action) => {
      misc.loadingContestResult = true;
    },
    contestResultRequestFailed: (misc, action) => {
      misc.loadingContestResult = false;
    },
    contestResultReceived: (misc, action) => {
      misc.contestResult = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loadingContestResult = false;
    },
    activeContestsRequested: (misc, action) => {
      misc.loading = true;
    },
    activeContestsReceived: (misc, action) => {
      misc.activeContests = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    statsArcadeRequested: (misc, action) => {
      misc.loadingStatsArcade = true;
    },
    statsLootRequested: (misc, action) => {
      misc.loadingStatsLoot = true;
    },
    contestStatsArcadeReceived: (misc, action) => {
      misc.contestStatsArcade = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loadingStatsArcade = false;
      misc.loading = false;
    },
    contestStatsLootReceived: (misc, action) => {
      misc.contestStatsLoot = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loadingStatsLoot = false;
      misc.loading = false;
    },
    statsArcadeRequestFailed: (misc, action) => {
      misc.loadingStatsArcade = false;
    },
    statsLootRequestFailed: (misc, action) => {
      misc.loadingStatsLoot = false;
    },
    leaderBoardReceived: (misc, action) => {
      misc.players = action.payload.data;
      misc.myGame = action.payload.myGame;
      if (action.payload.data.length < 2 || (action.payload.data.length === 2 && !action.payload.myGame)) {
        misc.empty += 1;
      }
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    dailyTriviaCheckReceived: (misc, action) => {
      misc.gameAvailable = action.payload.gameAvailable;
      misc.lastTriviaGame = action.payload.lastGame;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    leaderBoardReceived2: (misc, action) => {
      misc.players1 = action.payload.data;
      misc.myGame1 = action.payload.myGame;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    gameQuestionReceived: (misc, action) => {
      misc.question = action.payload;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    activeContestsRequestFailed: (misc, action) => {
      misc.loading = false;
      misc.error = action.payload.message;
    },
    pendingContestFailed: (misc, action) => {
      misc.loadingP = false;
      misc.error = action.payload.message;
    },
  },
});

export const {
  activeContestsRequested,
  activeContestsReceived,
  activeContestsRequestFailed,
  statsArcadeRequested,
  statsLootRequested,
  contestStatsArcadeReceived,
  contestStatsLootReceived,
  statsArcadeRequestFailed,
  statsLootRequestFailed,
  leaderBoardReceived,
  leaderBoardReceived2,
  gameQuestionReceived,
  dailyTriviaCheckReceived,
  contestDetailsReceived,
  contestDetailsRequested,
  contestDetailsRequestFailed,
  allContestReceived,
  contestResultReceived,
  contestResultRequested,
  contestResultRequestFailed,
  pendingContestRequested,
  pendingContestReceived,
  morePendingContestReceived,
  pendingContestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const gameUrl = "game/";
const contestUrl = "contest/";
const dailyTrivialGameUrl = "dailyTriviagame/";

export const loadContestDetailsStore = (id) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + id,
      onStart: contestDetailsRequested.type,
      onSuccess: contestDetailsReceived.type,
      onError: contestDetailsRequestFailed.type,
    }),
  );
};
export const loadContestDetails = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + id,
      onStart: contestDetailsRequested.type,
      onSuccess: contestDetailsReceived.type,
      onError: contestDetailsRequestFailed.type,
      callback,
    }),
  );
};
export const loadPendingContests = (params, callback) => (dispatch, getState) => {
  const more = params.more;
  delete params.more;
  const { lastFetchPendingContests } = getState().entities.contest;
  const diffInMinutes = moment().diff(moment(lastFetchPendingContests), "minutes");
  if (diffInMinutes < 10 && !more) return;
  return dispatch(
    apiCallBegan({
      url: contestUrl + "past",
      onStart: pendingContestRequested.type,
      onSuccess: !more ? pendingContestReceived.type : morePendingContestReceived.type,
      onError: pendingContestFailed.type,
      params,
      callback,
    }),
  );
};
export const loadMyActiveGames = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "active",
      onStart: activeContestsRequested.type,
      onSuccess: activeContestsReceived.type,
      onError: activeContestsRequestFailed.type,
      callback,
    }),
  );
};
export const loadContestResult = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "results/" + id,
      onStart: contestResultRequested.type,
      onSuccess: contestResultReceived.type,
      onError: contestResultRequestFailed.type,
      callback,
    }),
  );
};
export const loadLeaderBoard = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "leaderboard",
      params,
      onStart: activeContestsRequested.type,
      onSuccess: leaderBoardReceived.type,
      onError: activeContestsRequestFailed.type,
      callback,
    }),
  );
};
export const loadGameQuestion = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dailyTrivialGameUrl + "getQuestion",
      params,
      onStart: activeContestsRequested.type,
      onSuccess: gameQuestionReceived.type,
      onError: activeContestsRequestFailed.type,
      callback,
    }),
  );
};
export const loadLeaderBoard1 = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "leaderboard",
      params,
      onStart: activeContestsRequested.type,
      onSuccess: leaderBoardReceived2.type,
      onError: activeContestsRequestFailed.type,
      callback,
    }),
  );
};
export const loadAllContests = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl,
      onStart: activeContestsRequested.type,
      onSuccess: allContestReceived.type,
      onError: activeContestsRequestFailed.type,
      params,
      callback,
    }),
  );
};
export const loadContestsStatsArcade = (id, callback) => (dispatch) => {
  const params = { sendAll: true };
  return dispatch(
    apiCallBegan({
      url: contestUrl + "arcadeStats/" + id,
      onStart: statsArcadeRequested.type,
      onSuccess: contestStatsArcadeReceived.type,
      onError: statsArcadeRequestFailed.type,
      callback,
      params,
    }),
  );
};
export const loadContestsStatsLoot = (id, callback) => (dispatch) => {
  const params = { sendAll: true };
  return dispatch(
    apiCallBegan({
      url: contestUrl + "lootStats/" + id,
      onStart: statsLootRequested.type,
      onSuccess: contestStatsLootReceived.type,
      onError: statsLootRequestFailed.type,
      callback,
      params,
    }),
  );
};
export const loadAllContestsStats = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: contestUrl + "stats/" + id,
      params,
      onStart: activeContestsRequested.type,
      onSuccess: contestStatsArcadeReceived.type,
      onError: activeContestsRequestFailed.type,
      callback,
    }),
  );
};

export const sendAnswer = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dailyTrivialGameUrl + "sendAnswer",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const dailyTriviaCheck = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: dailyTrivialGameUrl + "check/",
      onStart: activeContestsRequested.type,
      onSuccess: dailyTriviaCheckReceived.type,
      onError: activeContestsRequestFailed.type,
      callback,
    }),
  );
};
export const getContests = createSelector(
  (state) => state.entities.contest,
  (contests) => contests,
);
