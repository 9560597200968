import _ from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  checkEmail,
  checkUsername,
  contestEntry,
  getUser,
  loadBountyBoxes,
  loadProfile,
  loginUser,
  registerUser,
  resendOTP,
  resetPassword,
  toggleDiscordModalRedux,
  toggleHTPRedux,
  toggleHowToPlayRedux,
  togglePopupRedux,
  updateImage,
  updateProfile,
  verifyOTP,
} from "store/user";
import { withRouter } from "utils/withRouter";

//  Components
import DateDropDown from "common/dateDropDown";
import moment from "moment";
import { dateAsUtc } from "utils/dateConversion";
import { verisoulLogout } from "utils/verisoulUtils";
import LoaderImg from "../../loaderImag";
//  Images
const logo = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/bgNewLogo.svg";
const loader = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/loader-grey.svg";
const usernameIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/username.svg";
const UPDATEUSERNAME = ({
  showModal,
  toggleModal,
  registerUser,
  checkUsername,
  togglePopupRedux,
  toggleHTPRedux,
  loadProfile,
  updateProfile,
  newAcc,
  checkEmail,
  setTab,
  updateImage,
  setOptionsType,
  toggleHowToPlayRedux,
}) => {
  // const { profile } = getUser;
  const profile = JSON.parse(localStorage.getItem("userProfile"));

  const [loadingUsername, setLoadingUsername] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isValidUserName, setIsValidUserName] = useState(false);
  const [data, setData] = useState({
    userName: "",
    email: "",
    date: {},
    term: false,
  });

  //  Method to check if username is valid or not
  const checkUsernameValidity = (userName) => {
    setLoadingUsername(true);
    checkUsername({ userName }, (usernameRes) => {
      const newErrors = structuredClone(errors);
      if (usernameRes.status === 200) {
        setIsValidUserName(true);
      } else {
        newErrors.userName = usernameRes.data.message;
        setErrors(newErrors);
        setIsValidUserName(false);
      }
      setLoadingUsername(false);
    });
  };

  // //  Method to check if email is valid or not
  // const checkEmailValidity = (email) => {
  //   const newErrors = structuredClone(errors);

  //   if (!_.isEmpty(email)) {
  //     var mailformat =
  //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //     if (!mailformat.test(email)) {
  //       newErrors.email = "Email is invalid";
  //       return setErrors(newErrors);
  //     }
  //     setLoadingEmail(true);
  //     checkEmail({ email }, (emailRes) => {
  //       if (emailRes.status === 200) {
  //         setIsValidEmail(true);
  //       } else {
  //         setIsValidEmail(false);
  //         if (emailRes.data.message === '""email" must be a valid email"') {
  //           newErrors.email = "Email is invalid";
  //         } else newErrors.email = emailRes.data.message;
  //         setErrors(newErrors);
  //       }
  //       setLoadingEmail(false);
  //     });
  //   }
  // };

  //  Method to toggle t&c check
  const handleCheck = (e) => {
    const { name, checked } = e;
    const newData = structuredClone(data);
    const newError = structuredClone(errors);
    newData[name] = checked;
    if (checked) delete newError[name];
    setData(newData);

    setErrors(newError);
  };

  //  Change text input
  const onChange = async (e) => {
    const { name, value } = e.target;
    const newErr = structuredClone(errors);
    const newData = structuredClone(data);
    delete newErr[name];
    newData[name] = value;
    setData(newData);
    setErrors(newErr);
  };

  useEffect(() => {
    const newData = structuredClone(data);
    const newErrors = structuredClone(errors);
    newData.userName = profile?.providerUserName?.toLocaleLowerCase()?.trim() ?? "";
    newData.email = profile?.providerUserEmail ?? "";
    if (profile?.providerUserEmail) {
      // setDisableEmail(true)
    } else {
      newErrors.email = "Email is required";
    }
    if (profile?.providerUserName?.length > 15) {
      newErrors.userName = "Username cannot be longer than 15 characters";
    } else {
      checkUsernameValidity(profile?.providerUserName);
    }
    setErrors(newErrors);
    setData(newData);
    // checkEmailValidity(newData.email);
  }, [profile?.providerUserName, profile?.providerUserEmail]);

  //  On Clicking on submit buttons data validation is done and api is called
  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (loading || loadingUsername) return;
    let newErrors = structuredClone(errors);

    //  Validations of the data
    if (_.isEmpty(data.userName)) newErrors.userName = "Username is required";
    if (!data?.date?.days || isNaN(data?.date?.months) || !data?.date?.years)
      newErrors.date = "Date of birth is required";
    else if (moment().diff(moment(data?.date), "years") < 18)
      newErrors.date = "You must be 18 or older to create an account";

    if (!_.isEmpty(newErrors)) {
      return setErrors(newErrors);
    }

    const { userName, email } = data;

    setLoading(true);
    updateProfile(
      { userName: userName.toLocaleLowerCase(), email, dateOfBirth: parseInt(dateAsUtc(data?.date, "x")) },
      (signupRes) => {
        if (signupRes?.status === 200) {
          loadProfile((res) => {
            if (!res.data.userProfile.image) {
              updateImage(
                {
                  image: `Avatars/${Math.floor(Math.random() * 140) + 1}.png`,
                },
                (res1) => {
                  loadProfile();
                },
              );
            }
            localStorage.setItem("userProfile", JSON.stringify(res?.data?.userProfile));
          });
          const userProfile = JSON.parse(localStorage.getItem("userProfile"));
          if (newAcc) {
            if (userProfile?.arcadeCoins > 0 || userProfile?.lootBonusCoins > 0) setTab(5);
            else {
              toggleHowToPlayRedux(true);
              toggleModal();
            }
          } else {
            toggleHowToPlayRedux(true);
            toggleModal();
          }
        } else {
          newErrors[signupRes.data.data] = signupRes.data.message;
          setErrors(newErrors);
        }
        setLoading(false);
      },
    );
  };

  const onChangeDate = (e) => {
    const { name, value } = e.target;
    const newErr = structuredClone(errors);
    const newData = structuredClone(data);
    delete newErr[name];
    newData[name] = value;

    if (value?.days && !isNaN(value?.months) && value?.years && moment().diff(moment(value), "years") < 18)
      newErr[name] = "You must be 18 or older to create an account";
    setData(newData);
    setErrors(newErr);
  };
  return (
    <>
      <div className="content-modal-box position-relative text-center">
        <div className="content-modal-header">
          <img className="content-modal-logo" src={logo} alt="" />
          <h5 className="obh-title">Finish Setup</h5>{" "}
        </div>
        <div>
          {/* <div
            className={`form-group ${loadingEmail && "loading"} ${
              errors.email ? "is-invalid" : !_.isEmpty(data.email) && isValidEmail && !loadingEmail ? "is-valid" : ""
            }`}
          >
            <div className="form-group-input position-relative">
              <div className="form-group-input-icon d-flex align-items-center justify-content-center rounded-circle">
                <img src={mailIcon} alt="" />
              </div>
              <input
                type="text"
                placeholder="Email Address"
                className="w-100 h-100 new-form-control"
                name="email"
                onChange={onChange}
                value={data.email}
                disabled={localStorage.getItem("ssoAuth") == "google" || disableEmail}
                onBlur={(e) => {
                  if (!e.target.value) {
                    const newErrors = structuredClone(errors);
                    newErrors.email = "Email cannot be empty";
                    setErrors(newErrors);
                    setIsValidEmail(false);
                    return;
                  }
                  checkEmailValidity(e.target.value);
                }}
              />{" "}
              <img src={loader} className="input-loader fa-spin" alt="" />
            </div>
            {errors.email && <div className="invalid-feedback text-start">{errors.email}</div>}
          </div> */}
          <div
            className={`form-group ${loadingUsername && "loading"} ${
              errors.userName
                ? "is-invalid"
                : !_.isEmpty(data.userName) && isValidUserName && !loadingUsername
                ? "is-valid"
                : ""
            }`}
          >
            <div className="form-group-input position-relative">
              <div className="form-group-input-icon d-flex align-items-center justify-content-center rounded-circle">
                <img src={usernameIcon} alt="" />
              </div>
              <input
                type="text"
                className="w-100 h-100 new-form-control"
                placeholder="Username"
                name="userName"
                onChange={onChange}
                onBlur={(e) => {
                  if (!e.target.value) {
                    const newErrors = structuredClone(errors);
                    newErrors.userName = "Username cannot be empty";
                    setErrors(newErrors);
                    setIsValidUserName(false);
                    return;
                  }
                  checkUsernameValidity(e.target.value);
                }}
                maxLength={15}
                value={data.userName}
              />
              <img src={loader} className="input-loader fa-spin" alt="" />
            </div>
            {errors.userName && <div className="invalid-feedback text-start">{errors.userName}</div>}
          </div>
          <div className={"form-group" + (errors.date ? " is-invalid" : "")}>
            <div className="form-label-area fw-bold d-flex">Date of Birth</div>
            <DateDropDown onChangeDate={onChangeDate} />
            {errors.date && <div className="invalid-feedback text-start">{errors.date}</div>}
          </div>
          <div className="tick-checkbox-group mb-5 px-sm-2">
            <div className={"form-check tick-checkbox " + (errors.term ? "is-invalid" : "")}>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="tcCheckbox"
                name="term"
                onClick={(e) => handleCheck({ name: "term", checked: !data.term })}
                checked={data.term}
              />
              <label className="form-check-label" for="tcCheckbox">
                <div className="text-start subtext-onboarding">
                  By clicking the Agree and Create Account button below, I represent that I am at least 18 years of age,
                  I have read and agree to the{" "}
                  <a href="/terms-of-service" className="blank-blue-link" target="_blank">
                    Terms & Conditions
                  </a>
                  , and{" "}
                  <a href="/sweepstakes-rules" className="blank-blue-link" target="_blank">
                    Sweepstakes Rules
                  </a>
                  , and{" "}
                  <a href="/privacy-policy" className="blank-blue-link" target="_blank">
                    Privacy Policy
                  </a>{" "}
                  and agree to receive email marketing materials from Burn Ghost, Inc. and its partners.
                </div>
                {errors.term && (
                  <div className="invalid-feedback text-start">
                    Please confirm that you are at least 18 years of age and agree to the Burn Ghost Terms & Conditions
                  </div>
                )}
              </label>
            </div>
          </div>
        </div>

        <div className="form-group-button">
          <button
            type="submit"
            className={`btn btn-gradient-default btn-block common-btn-gradient ${
              (loading ||
                loadingUsername ||
                errors.userName ||
                !data.term ||
                // loadingEmail ||
                errors.email ||
                errors?.date ||
                !data?.date?.days ||
                isNaN(data?.date?.months) ||
                !data?.date?.years) &&
              "disabled pointer-none"
            } ${loading && "loading"}`}
            onClick={handleSubmit}
          >
            {loading ? <LoaderImg /> : "Agree and Create Account"}
          </button>
        </div>
      </div>
      <div className="blank-label-box fw-semi-bold mt-0">
        <span className="d-block text-center">
          Have an existing Burn Ghost account? <br />
          <a
            href="#!"
            className="blank-blue-link"
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              localStorage.setItem("logout", "true");
              localStorage.setItem("`tryLogin`", "false");
              localStorage.setItem("signed", "false");
              localStorage.removeItem("x-auth-token");
              verisoulLogout();
              localStorage.removeItem("userProfile");
              setOptionsType("login");
              setTab(1);
            }}
          >
            {" "}
            Sign in with Burn Ghost
          </a>{" "}
        </span>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  contestEntry: (data, callback) => dispatch(contestEntry(data, callback)),
  loadProfile: (callback) => dispatch(loadProfile(callback)),
  loginUser: (data, callback) => dispatch(loginUser(data, callback)),
  verifyOTP: (data, callback) => dispatch(verifyOTP(data, callback)),
  checkUsername: (data, callback) => dispatch(checkUsername(data, callback)),
  checkEmail: (data, callback) => dispatch(checkEmail(data, callback)),
  updateImage: (data, callback) => dispatch(updateImage(data, callback)),
  registerUser: (data, callback) => dispatch(registerUser(data, callback)),
  resendOTP: (data, callback) => dispatch(resendOTP(data, callback)),
  resetPassword: (data, callback) => dispatch(resetPassword(data, callback)),
  togglePopupRedux: (data) => dispatch(togglePopupRedux(data)),
  toggleHTPRedux: (data) => dispatch(toggleHTPRedux(data)),
  toggleHowToPlayRedux: (data) => dispatch(toggleHowToPlayRedux(data)),
  toggleDiscordModalRedux: (data) => dispatch(toggleDiscordModalRedux(data)),
  loadBountyBoxes: (callback) => dispatch(loadBountyBoxes(callback)),
  updateProfile: (data, callback) => dispatch(updateProfile(data, callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UPDATEUSERNAME));
