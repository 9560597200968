import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "user",
  initialState: {
    profile: {
      userProfile: {},
      connectedWallet: null,
      data: [],
      highScoreGame: {},
      allWinnings: {},
    },
    profileLoaded: localStorage.getItem("x-auth-token") ? false : true,
    transactions: { data: [], totalCount: 0, filteredCount: 0, lastKey: null },
    activities: [],
    arcadeActivityLastKey: null,
    lootActivities: [],
    lootActivityLastKey: null,
    transactionsLoading: false,
    loading: false,
    loadingPrice: false,
    lastFetchPrice: null,
    error: null,
    isWalletConnected: false,
    isWalletLoading: true,
    activitiesLoading: false,
    notifications: [],
    unreadCount: 0,
    creditPrices: [],
    creditPriceBonusPercent: 0,
    messageToSign: null,
    messageToSignLoading: true,
    showLoginModal: false,
    showChooseWalletModal: false,
    showHTPAfter: false,
    showConnectWalletAfter: false,
    showOnboardingModal: false,
    showBuyCreditsModal: false,
    showHowToPlay: false,
    showDiscordModal: false,
    transactionId: null,
    availableTickets: 0,
    usdtBalance: [],
    availableTicketsLoading: true,
    userGameData: null,
    userGameDataLoading: false,
    showPurchaseCoins: false,
    showBountyBoxModal: false,
    howItWorksModalType: "",
    bountyBoxes: [],
    bountyBoxesReceivedAt: null,
    bountyBoxesTotal: null,
    bountyBoxDetails: null,
    bountyBoxLoading: false,
    bountyBoxDetailsLoading: false,
    googleAuthUrl: "",
    googleAuthURLLoading: false,
    fbAuthUrl: "",
    fbAuthURLLoading: false,
    discordAuthUrl: "",
    discordAuthURLLoading: false,
    showWalletModal: false,
    dailyGameChallengeLoading: false,
    dailyGameChallenge: null,
    dailyGameChallengeEligiblityLoading: false,
    dailyGameChallengeEligiblity: null,
    dailyStreakRewardStatusLoading: false,
    dailyStreakRewardStatus: null,
    threeDayStreakRewardStatusLoading: false,
    threeDayStreakRewardStatus: null,
    weeklyStreakRewardStatusLoading: false,
    weeklyStreakRewardStatus: null,
    zRunNft: null,
    zRunNFTLoading: false,
    walletConnectLoading: false,
    walletDisconnectLoading: false,
    referralCodesLoading: false,
    referralCodes: [],
    referralRewardsLoading: false,
    referralRewards: {},
    slides: [],
    slidesLoading: false,
    rewards: {},
    lastFetchRewards: null,
    lastFetchSlides: null,
    lastFetchDailyRewardStatus: null,
    lastFetchThreeDayRewardStatus: null,
    lastFetchWeeklyRewardStatus: null,
  },

  reducers: {
    showHideLoginModal: (misc, action) => {
      misc.showLoginModal = action.payload;
    },
    showHideWalletModal: (misc, action) => {
      misc.showWalletModal = action.payload;
    },
    showHideChooseWalletModal: (misc, action) => {
      misc.showChooseWalletModal = action.payload;
    },
    showHideHTPAfterModal: (misc, action) => {
      misc.showHTPAfter = action.payload;
    },
    showHideConnectWalletAfterModal: (misc, action) => {
      misc.showConnectWalletAfter = action.payload;
    },
    showHideOnboardingModal: (misc, action) => {
      misc.showOnboardingModal = action.payload;
    },
    showHideHowToPlayModal: (misc, action) => {
      misc.showHowToPlay = action.payload;
    },
    showHidePurchaseCoinsModal: (misc, action) => {
      misc.showPurchaseCoins = action.payload;
    },
    showHideBountyBoxModal: (misc, action) => {
      misc.showBountyBoxModal = action.payload;
    },
    showHideDiscordModal: (misc, action) => {
      misc.showDiscordModal = action.payload;
    },
    showHideBuyCreditsModal: (misc, action) => {
      misc.showBuyCreditsModal = action.payload;
    },
    disconnectWalletRequested: (misc, action) => {
      misc.walletDisconnectLoading = true;
    },

    disconnectWalletReceived: (misc, action) => {
      misc.profile.userProfile.externalWalletAddress = null;
      misc.messageToSignLoading = true;
      misc.walletDisconnectLoading = false;
    },

    disconnectWalletRequestFailed: (misc, action) => {
      misc.walletDisconnectLoading = false;
    },

    walletConnectRequested: (misc, action) => {
      misc.isWalletLoading = true;
    },
    walletConnectReceived: (misc, action) => {
      misc.isWalletConnected = true;
      misc.isWalletLoading = false;
    },
    walletConnectRequestFailed: (misc, action) => {
      misc.isWalletLoading = false;
    },

    //
    messageToSignRequested: (misc, action) => {
      misc.messageToSignLoading = true;
    },
    messageToSignReceived: (misc, action) => {
      misc.messageToSign = action.payload.signatureMessage;
      misc.messageToSignLoading = false;
    },
    messageToSignRequestFailed: (misc, action) => {
      misc.messageToSignLoading = true;
    },

    profileRequested: (misc, action) => {
      misc.loading = true;
      misc.profileLoaded = false;
      misc.profile.userProfile.externalWalletAddress = "";
    },
    priceRequested: (misc, action) => {
      misc.loadingPrice = true;
    },
    priceRequestFailed: (misc, action) => {
      misc.loadingPrice = false;
    },
    profileReceived: (misc, action) => {
      misc.profile = action.payload;
      misc.lastFetch = Date.now();
      misc.loading = false;
      misc.profileLoaded = true;
    },

    walletConnectionRequested: (misc, action) => {
      misc.walletConnectLoading = true;
    },
    walletConnectionFailed: (misc, action) => {
      misc.walletConnectLoading = false;
    },
    walletConnectionReceived: (misc, action) => {
      misc.walletConnectLoading = false;
    },

    notificationsReceived: (misc, action) => {
      misc.notifications = action.payload.data;
      misc.unreadCount = action.payload.unreadCount;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    profileRequestFailed: (misc, action) => {
      misc.loading = false;
      misc.error = action.payload.message;
    },
    changeUnreadCount: (misc, action) => {
      misc.unreadCount = 0;
    },
    reduceUnreadCount: (misc, action) => {
      misc.unreadCount = action.payload;
    },
    transactionRequested: (misc, action) => {
      misc.transactionsLoading = true;
    },
    transactionReceived: (misc, action) => {
      misc.transactions = action.payload;
      misc.transactions.totalCount = action.payload.totalCount;
      misc.transactions.filteredCount = action.payload.filteredCount;
      misc.transactions.lastKey = action.payload.lastKey;
      misc.lastFetch = Date.now();
      misc.transactionsLoading = false;
    },
    activitiesRequested: (misc, action) => {
      misc.activitiesLoading = true;
    },
    activitiesReceived: (misc, action) => {
      misc.activities = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
      misc.arcadeActivityLastKey = action.payload.lastKey;
    },
    moreActivitiesReceived: (misc, action) => {
      misc.activities = [...misc.activities, ...action.payload.data];
      misc.lastFetch = Date.now();
      misc.loading = false;
      misc.arcadeActivityLastKey = action.payload.lastKey;
    },
    activitiesRequestFailed: (misc, action) => {
      misc.loading = false;
      misc.error = action.payload.message;
    },
    //  ====> Loot Activities
    lootActivitiesRequested: (misc, action) => {
      misc.activitiesLoading = true;
    },
    lootActivitiesReceived: (misc, action) => {
      misc.lootActivities = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
      misc.lootActivityLastKey = action.payload.lastKey;
    },
    moreLootActivitiesReceived: (misc, action) => {
      misc.lootActivities = [...misc.lootActivities, ...action.payload.data];
      misc.lastFetch = Date.now();
      misc.loading = false;
      misc.lootActivityLastKey = action.payload.lastKey;
    },
    lootActivitiesRequestFailed: (misc, action) => {
      misc.loading = false;
      misc.error = action.payload.message;
    },
    //  <=== Loot Activities

    transactionRequestFailed: (misc, action) => {
      misc.transactionsLoading = false;
      misc.error = action.payload.message;
    },

    transactionLoadMoreReceived: (misc, action) => {
      misc.transactions.data = [...misc.transactions.data, ...action.payload.data];
      misc.transactions.totalCount = action.payload.totalCount;
      misc.transactions.filteredCount = action.payload.filteredCount;
      misc.transactions.lastKey = action.payload.lastKey;

      misc.lastFetch = Date.now();
      misc.transactionsLoading = false;
    },
    creditPricesReceived: (misc, action) => {
      misc.creditPrices = action.payload.data;
      misc.creditPriceBonusPercent = action.payload.creditPriceBonusPercent;
      misc.lastFetchPrice = Date.now();
      misc.loadingPrice = false;
    },

    enterContestSuccess: (misc, action) => {
      misc.transactionId = action.payload.data.id;
    },

    availableTicketsRequested: (misc, action) => {
      misc.availableTicketsLoading = true;
    },
    availableTicketsReceived: (misc, action) => {
      misc.availableTickets = action.payload?.data?.availableTickets;
      misc.usdtBalance = action.payload?.data?.usdtBalance;
      misc.lastFetch = Date.now();
      misc.availableTicketsLoading = false;
    },
    availableTicketsRequestFailed: (misc, action) => {
      misc.availableTicketsLoading = false;
      misc.error = action?.payload?.message;
    },

    //  Game user data
    userGameDataRequested: (misc, action) => {
      misc.userGameDataLoading = true;
    },
    userGameDataRequestFailed: (misc, action) => {
      misc.userGameDataLoading = false;
    },
    userGameDataReceived: (misc, action) => {
      misc.profile = action.payload;
      misc.userGameDataLoading = false;
    },

    //  Bounty Box Reducers
    bountyBoxesRequested: (misc, action) => {
      misc.bountyBoxLoading = true;
    },
    bountyBoxesRequestFailed: (misc, action) => {
      misc.bountyBoxLoading = false;
    },
    bountyBoxesReceived: (misc, action) => {
      misc.bountyBoxes = action.payload?.data;
      misc.bountyBoxesTotal = action.payload?.total ?? null;
      misc.bountyBoxLoading = false;
      misc.bountyBoxesReceivedAt = Date.now();
    },
    bountyBoxDetailsRequested: (misc, action) => {
      misc.bountyBoxDetailsLoading = true;
    },
    bountyBoxDetailsRequestFailed: (misc, action) => {
      misc.bountyBoxDetailsLoading = false;
    },
    bountyBoxDetailsReceived: (misc, action) => {
      misc.bountyBoxDetails = action.payload.data;
      misc.bountyBoxDetailsLoading = false;
    },

    // Google SSO
    googleAuthUrlRequested: (misc, action) => {
      misc.googleAuthURLLoading = true;
    },
    googleAuthUrlRequestFailed: (misc, action) => {},
    googleAuthUrlReceived: (misc, action) => {
      misc.googleAuthUrl = action.payload.data;
    },
    googleAuthUrlLoading: (misc, action) => {
      misc.googleAuthURLLoading = action.payload;
    },

    // Facebook SSO
    fbAuthUrlRequested: (misc, action) => {
      misc.fbAuthURLLoading = true;
    },
    fbAuthUrlRequestFailed: (misc, action) => {},
    fbAuthUrlReceived: (misc, action) => {
      misc.fbAuthUrl = action.payload.data;
    },
    fbAuthUrlLoading: (misc, action) => {
      misc.fbAuthURLLoading = action.payload;
    },

    // Google SSO
    discordAuthUrlRequested: (misc, action) => {
      misc.discordAuthURLLoading = true;
    },
    discordAuthUrlRequestFailed: (misc, action) => {},
    discordAuthUrlReceived: (misc, action) => {
      misc.discordAuthUrl = action.payload.data;
    },
    discordAuthUrlLoading: (misc, action) => {
      misc.discordAuthURLLoading = action.payload;
    },

    //rewards
    rewardsRequested: (misc, action) => {
      misc.rewardsLoading = true;
    },
    rewardsRequestFailed: (misc, action) => {
      misc.rewardsLoading = false;
    },
    rewardsReceived: (misc, action) => {
      misc.rewards = action.payload.data;
      misc.lastFetchRewards = Date.now();
      misc.rewardsLoading = false;
    },
    //slides
    slidesRequested: (misc, action) => {
      misc.slidesLoading = true;
      misc.lastFetchSlides = Date.now();
    },
    slidesReceived: (misc, action) => {
      misc.slides = action.payload.data;
      misc.lastFetchSlides = Date.now();
      misc.slidesLoading = false;
    },
    slidesRequestFailed: (misc, action) => {
      misc.slidesLoading = false;
      misc.lastFetchSlides = null;
    },
    dailyGameChallengeRequested: (misc, action) => {
      misc.dailyGameChallengeLoading = true;
    },
    dailyGameChallengeRequestFailed: (misc, action) => {
      misc.dailyGameChallengeLoading = false;
    },
    dailyGameChallengeReceived: (misc, action) => {
      misc.dailyGameChallenge = action.payload;
      misc.dailyGameChallengeLoading = false;
    },
    dailyGameChallengeEligiblityRequested: (misc, action) => {
      misc.dailyGameChallengeEligiblityLoading = true;
    },
    dailyGameChallengeEligiblityRequestFailed: (misc, action) => {
      misc.dailyGameChallengeEligiblityLoading = false;
    },
    dailyGameChallengeEligiblityReceived: (misc, action) => {
      misc.dailyGameChallengeEligiblity = action.payload;
      misc.dailyGameChallengeEligiblityLoading = false;
    },
    dailyStreakRewardStatusRequested: (misc, action) => {
      misc.dailyStreakRewardStatusLoading = true;
    },
    dailyStreakRewardStatusRequestFailed: (misc, action) => {
      misc.dailyStreakRewardStatusLoading = false;
    },
    dailyStreakRewardStatusReceived: (misc, action) => {
      misc.dailyStreakRewardStatus = action.payload;
      misc.lastFetchDailyRewardStatus = Date.now();
      misc.dailyStreakRewardStatusLoading = false;
    },
    threeDayStreakRewardStatusRequested: (misc, action) => {
      misc.threeDayStreakRewardStatusLoading = true;
    },
    threeDayStreakRewardStatusRequestFailed: (misc, action) => {
      misc.threeDayStreakRewardStatusLoading = false;
    },
    threeDayStreakRewardStatusReceived: (misc, action) => {
      misc.threeDayStreakRewardStatus = action.payload;
      misc.lastFetchThreeDayRewardStatus = Date.now();
      misc.threeDayStreakRewardStatusLoading = false;
    },
    weeklyStreakRewardStatusRequested: (misc, action) => {
      misc.weeklyStreakRewardStatusLoading = true;
    },
    weeklyStreakRewardStatusRequestFailed: (misc, action) => {
      misc.weeklyStreakRewardStatusLoading = false;
    },
    weeklyStreakRewardStatusReceived: (misc, action) => {
      misc.weeklyStreakRewardStatus = action.payload;
      misc.lastFetchWeeklyRewardStatus = Date.now();
      misc.weeklyStreakRewardStatusLoading = false;
    },
    referralCodesRequested: (misc, action) => {
      misc.referralCodesLoading = true;
    },
    referralCodesRequestFailed: (misc, action) => {
      misc.referralCodesLoading = false;
    },
    referralCodesReceived: (misc, action) => {
      misc.referralCodes = action?.payload?.data ?? [];
      misc.referralCodesLoading = false;
    },
    referralRewardsRequested: (misc, action) => {
      misc.referralRewardsLoading = true;
    },
    referralRewardsRequestFailed: (misc, action) => {
      misc.referralRewardsLoading = false;
    },
    referralRewardsReceived: (misc, action) => {
      misc.referralRewards = action?.payload?.data;
      misc.referralRewardsLoading = false;
    },
    dailyStreakRewardRedeemed: (misc, action) => {
      misc.dailyStreakRewardStatus.isRedeemed = true;
      misc.threeDayStreakRewardStatus.threeDayStreak = Math.min(
        (misc?.threeDayStreakRewardStatus?.threeDayStreak ?? 0) + 1,
        3,
      );
      misc.weeklyStreakRewardStatus.weeklyStreak = Math.min((misc?.weeklyStreakRewardStatus?.weeklyStreak ?? 0) + 1, 5);
      misc.profile.userProfile.arcadeCoins =
        misc.profile.userProfile?.arcadeCoins + misc.rewards?.dailyArcadeCoinReward;
      misc.profile.userProfile.lootBonusCoins =
        misc.profile.userProfile?.lootBonusCoins + misc.rewards?.dailyLootBonusCoinReward;
    },
    threeDayStreakRewardRedeemed: (misc, action) => {
      misc.threeDayStreakRewardStatus.isRedeemed = true;
      misc.profile.userProfile.arcadeCoins =
        misc.profile.userProfile?.arcadeCoins + misc.rewards?.threeDayStreakArcadeCoinReward;
      misc.profile.userProfile.lootBonusCoins =
        misc.profile.userProfile?.lootBonusCoins + misc.rewards?.threeDayStreakLootBonusCoinReward;
    },
    weeklyStreakRewardRedeemed: (misc, action) => {
      misc.weeklyStreakRewardStatus.isRedeemed = true;
      misc.profile.userProfile.arcadeCoins =
        misc.profile.userProfile?.arcadeCoins + misc.rewards?.weeklyStreakArcadeCoinReward;
      misc.profile.userProfile.lootBonusCoins =
        misc.profile.userProfile?.lootBonusCoins + misc.rewards?.weeklyStreakLootBonusCoinReward;
    },
    dailyGameChallengeRewardRedeemed: (misc, action) => {
      misc.dailyGameChallengeEligiblity.isRedeemed = true;
      misc.profile.userProfile.arcadeCoins =
        misc.profile.userProfile?.arcadeCoins + misc.dailyGameChallenge?.bgGame?.dailyGameChallengeArcadeCoinReward;
      misc.profile.userProfile.lootBonusCoins =
        misc.profile.userProfile?.lootBonusCoins +
        misc.dailyGameChallenge?.bgGame?.dailyGameChallengeLootBonusCoinReward;
    },
    checkzRunNftRequested: (misc, action) => {
      misc.zRunNFTLoading = true;
    },
    checkzRunNftReceived: (misc, action) => {
      misc.zRunNft = action.payload;
      misc.zRunNFTLoading = false;
    },
    checkzRunNftRequestFailed: (misc, action) => {
      misc.zRunNFTLoading = false;
    },
    arcadeCoinsUpdated: (misc, action) => {
      misc.profile.userProfile.arcadeCoins = misc.profile.userProfile?.arcadeCoins + action.payload;
    },
    ticketsUpdated: (misc, action) => {
      misc.availableTickets = misc.availableTickets + action.payload;
    },
    lootBonusCoinsUpdated: (misc, action) => {
      misc.profile.userProfile.lootBonusCoins = misc.profile.userProfile?.lootBonusCoins + action.payload;
    },
  },
});

export const {
  walletConnectionRequested,
  walletConnectionFailed,
  walletConnectionReceived,
  profileReceived,
  profileRequestFailed,
  profileRequested,
  transactionReceived,
  transactionRequestFailed,
  transactionRequested,
  transactionLoadMoreReceived,
  walletConnectRequested,
  walletConnectReceived,
  walletConnectRequestFailed,
  activitiesRequested,
  activitiesReceived,
  activitiesRequestFailed,
  notificationsReceived,
  changeUnreadCount,
  reduceUnreadCount,
  creditPricesReceived,
  priceRequested,
  priceRequestFailed,
  messageToSignRequested,
  messageToSignReceived,
  messageToSignRequestFailed,
  disconnectWalletRequested,
  disconnectWalletReceived,
  disconnectWalletRequestFailed,
  showHideLoginModal,
  showHideOnboardingModal,
  showHideBuyCreditsModal,
  showHideHowToPlayModal,
  showHideHTPAfterModal,
  showHideConnectWalletAfterModal,
  enterContestSuccess,
  showHideDiscordModal,
  availableTicketsRequested,
  availableTicketsReceived,
  availableTicketsRequestFailed,
  userGameDataRequested,
  userGameDataRequestFailed,
  userGameDataReceived,
  showHidePurchaseCoinsModal,
  showHideBountyBoxModal,
  bountyBoxesRequested,
  bountyBoxesRequestFailed,
  bountyBoxesReceived,
  bountyBoxDetailsRequested,
  bountyBoxDetailsRequestFailed,
  bountyBoxDetailsReceived,
  googleAuthUrlRequested,
  googleAuthUrlRequestFailed,
  googleAuthUrlReceived,
  googleAuthUrlLoading,
  fbAuthUrlRequested,
  fbAuthUrlRequestFailed,
  fbAuthUrlReceived,
  fbAuthUrlLoading,
  discordAuthUrlRequested,
  discordAuthUrlRequestFailed,
  discordAuthUrlReceived,
  discordAuthUrlLoading,
  showHideChooseWalletModal,
  showHideWalletModal,
  lootActivitiesRequested,
  lootActivitiesReceived,
  lootActivitiesRequestFailed,
  moreActivitiesReceived,
  moreLootActivitiesReceived,
  rewardsRequested,
  rewardsRequestFailed,
  rewardsReceived,
  dailyGameChallengeRequested,
  dailyGameChallengeRequestFailed,
  dailyGameChallengeReceived,
  dailyGameChallengeEligiblityRequested,
  dailyGameChallengeEligiblityRequestFailed,
  dailyGameChallengeEligiblityReceived,
  dailyStreakRewardStatusRequested,
  dailyStreakRewardStatusRequestFailed,
  dailyStreakRewardStatusReceived,
  threeDayStreakRewardStatusRequested,
  threeDayStreakRewardStatusRequestFailed,
  threeDayStreakRewardStatusReceived,
  weeklyStreakRewardStatusRequested,
  weeklyStreakRewardStatusReceived,
  weeklyStreakRewardStatusRequestFailed,
  dailyStreakRewardRedeemed,
  threeDayStreakRewardRedeemed,
  weeklyStreakRewardRedeemed,
  dailyGameChallengeRewardRedeemed,
  checkzRunNftRequested,
  checkzRunNftReceived,
  checkzRunNftRequestFailed,
  arcadeCoinsUpdated,
  lootBonusCoinsUpdated,
  ticketsUpdated,
  referralCodesRequested,
  referralCodesReceived,
  referralCodesRequestFailed,
  referralRewardsRequested,
  referralRewardsReceived,
  referralRewardsRequestFailed,
  slidesRequested,
  slidesReceived,
  slidesRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const authUrl = "onboarding/login";

export const toggleWalletPopupRedux = (show) => (dispatch) => {
  dispatch(showHideWalletModal(show));
};

export const togglePopupRedux = (show) => (dispatch) => {
  dispatch(showHideLoginModal(show));
};
export const toggleChooseWalletRedux = (show) => (dispatch) => {
  dispatch(showHideChooseWalletModal(show));
};
export const toggleHTPRedux = (show) => (dispatch) => {
  dispatch(showHideHTPAfterModal(show));
};
export const toggleConnectWalletRedux = (show) => (dispatch) => {
  dispatch(showHideConnectWalletAfterModal(show));
};
export const toggleHowToPlayRedux = (show) => (dispatch) => {
  dispatch(showHideHowToPlayModal(show));
};
export const togglePurchaseCoinsRedux = (show) => (dispatch) => {
  dispatch(showHidePurchaseCoinsModal(show));
};
export const toggleBountyBoxRedux = (show) => (dispatch) => {
  dispatch(showHideBountyBoxModal(show));
};
export const toggleDiscordModalRedux = (show) => (dispatch) => {
  dispatch(showHideDiscordModal(show));
};
export const toggleOnboardingPopup = (show) => (dispatch) => {
  dispatch(showHideOnboardingModal(show));
};

export const toggleBuyCreditsPopup = (show) => (dispatch) => {
  dispatch(showHideBuyCreditsModal(show));
};

//  SSO Loading Toggles
export const toggleGoogleAuthUrlLoading = (show) => (dispatch) => {
  dispatch(googleAuthUrlLoading(show));
};
export const toggleFBAuthUrlLoading = (show) => (dispatch) => {
  dispatch(fbAuthUrlLoading(show));
};
export const toggleDiscordAuthUrlLoading = (show) => (dispatch) => {
  dispatch(discordAuthUrlLoading(show));
};
export const updateRewardStreak = () => (dispatch) => {
  dispatch(dailyStreakRewardRedeemed());
};
export const updateThreeDayStreak = () => (dispatch) => {
  dispatch(threeDayStreakRewardRedeemed());
};
export const updateWeeklyStreak = () => (dispatch) => {
  dispatch(weeklyStreakRewardRedeemed());
};
export const updateGameChallengeReward = () => (dispatch) => {
  dispatch(dailyGameChallengeRewardRedeemed());
};
export const updateLootBonusCoins = (data) => (dispatch) => {
  dispatch(lootBonusCoinsUpdated(data));
};
export const updateTickets = (data) => (dispatch) => {
  dispatch(ticketsUpdated(data));
};
export const updateArcadeCoins = (data) => (dispatch) => {
  dispatch(arcadeCoinsUpdated(data));
};
export const disconnectWallet = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/disconnectWallet",
      onStart: disconnectWalletRequested.type,
      onSuccess: disconnectWalletReceived.type,
      onError: disconnectWalletRequestFailed.type,
      callback,
    }),
  );
};

export const loadMessageToSign = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/getMessage",
      onStart: messageToSignRequested.type,
      onSuccess: messageToSignReceived.type,
      onError: messageToSignRequestFailed.type,
    }),
  );
};

export const loadWalletMessageToSign = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/getMessage",
      onStart: messageToSignRequested.type,
      onSuccess: messageToSignReceived.type,
      onError: messageToSignRequestFailed.type,
    }),
  );
};

export const loadProfile = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/profile",
      onStart: profileRequested.type,
      onSuccess: profileReceived.type,
      onError: profileRequestFailed.type,
      callback,
    }),
  );
};

export const loadTransactions =
  (page = 1) =>
  (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: "transactions",
        params: { page },
        onStart: transactionRequested.type,
        onSuccess: transactionReceived.type,
        onError: transactionRequestFailed.type,
      }),
    );
  };
export const loadNotifications = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/notifications",
      onStart: profileRequested.type,
      onSuccess: notificationsReceived.type,
      onError: profileRequestFailed.type,
      callback,
    }),
  );
};
export const loadUserGameData = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "gameCount/" + id,
      onStart: userGameDataRequested.type,
      onSuccess: userGameDataReceived.type,
      onError: userGameDataRequestFailed.type,
      callback,
    }),
  );
};
export const loadActivities = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/activities",
      onStart: activitiesRequested.type,
      onSuccess: activitiesReceived.type,
      onError: activitiesRequestFailed.type,
      callback,
    }),
  );
};
export const loadMoreActivities = (lastKey, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/activities",
      params: { lastKey },
      onStart: activitiesRequested.type,
      onSuccess: moreActivitiesReceived.type,
      onError: activitiesRequestFailed.type,
      callback,
    }),
  );
};

export const loadLootActivities = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/lootBucksActivities",
      onStart: lootActivitiesRequested.type,
      onSuccess: lootActivitiesReceived.type,
      onError: lootActivitiesRequestFailed.type,
      callback,
    }),
  );
};
export const loadMoreLootActivities = (lastKey, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/lootBucksActivities",
      params: { lastKey },
      onStart: lootActivitiesRequested.type,
      onSuccess: moreLootActivitiesReceived.type,
      onError: lootActivitiesRequestFailed.type,
      callback,
    }),
  );
};

export const loadMoreTransactions = (lastKey, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "transactions",
      params: { lastKey },
      onStart: transactionRequested.type,
      onSuccess: transactionLoadMoreReceived.type,
      onError: transactionRequestFailed.type,
      callback,
    }),
  );
};

export const checkWallet = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/checkWallet",
      method: "POST",
      data,
      onStart: walletConnectRequested.type,
      onSuccess: walletConnectReceived.type,
      onError: walletConnectRequestFailed.type,
      callback,
    }),
  );
};
export const registerUser = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/registration",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loginUser = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/login",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const sendOTP = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/sendOtp",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const resendOTP = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/resendOtp",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const resetPassword = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/resetPassword",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const verifyOTP = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/verifyOtp",
      method: "POST",
      data,
      callback,
    }),
  );
};

export const markNotificationAsRead = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/notifications/read",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const signIn = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/connectWallet",
      method: "POST",
      data,
      callback,
      onStart: walletConnectionRequested.type,
      onSuccess: walletConnectionReceived.type,
      onError: walletConnectRequestFailed.type,
    }),
  );
};
export const walletLogin = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/walletLogin",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const connectNFT = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/checkWallet",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const requestCredits = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/requestCredits",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const updateProfile = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/profile",
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const redeemToken = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/redeemToken",
      method: "POST",
      data,
      callback,
    }),
  );
};

export const contestEntry = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "transaction/entercontest",
      onSuccess: enterContestSuccess.type,
      method: "POST",
      data,
      callback,
    }),
  );
};

export const updateImage = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/profileImage",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const logout = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: authUrl + "logout",
      method: "POST",
      callback,
    }),
  );
};
export const loadCreditPrices = (callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "creditPrice",
      onStart: priceRequested.type,
      onSuccess: creditPricesReceived.type,
      onError: priceRequestFailed.type,
      callback,
    }),
  );
};

//Create charge
export const createCharge = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "purchaseCredit/createCharge/" + id,
      method: "POST",
      callback,
    }),
  );
};

//Purchase Coins Package
export const purchaseCoins = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "creditPrice/purchase/" + id,
      method: "POST",
      data,
      callback,
    }),
  );
};

//  Create Stripe Payment Intent
export const createPaymentIntent = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "purchaseCredit/createStripePaymentIntent/" + id,
      method: "POST",
      data,
      callback,
    }),
  );
};

//  Create Stripe Payment Intent
export const stripeWebhook = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "purchaseCredit/stripeWebhook",
      method: "POST",
      data,
      callback,
    }),
  );
};

//Create charge
export const redeemCredit = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/redeemDailyCredit",
      method: "GET",
      callback,
    }),
  );
};
export const processTransaction = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "transactions/process",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const checkUsername = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/checkUserName",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const checkEmail = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/checkEmail",
      method: "POST",
      data,
      callback,
    }),
  );
};

export const loadAvailableTickets = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/availableTickets",
      onStart: availableTicketsRequested.type,
      onSuccess: availableTicketsReceived.type,
      onError: availableTicketsRequestFailed.type,
      callback,
    }),
  );
};
export const createWallet = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/createWallet",
      method: "POST",
      data,
      callback,
    }),
  );
};

//  Transfer/Withdraw USDT
export const calculateGasFee = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/calculateGasFee",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const transferAsset = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/transferAsset",
      method: "POST",
      data,
      callback,
    }),
  );
};

export const sendPassCodeOTP = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/resetPasscodeOtp",
      method: "GET",
      callback,
    }),
  );
};
export const resetPassCode = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/resetPasscode",
      method: "POST",
      data,
      callback,
    }),
  );
};

//  Bounty Box Redemptions Actions
export const loadBountyBoxes = (callback, hardRefresh) => (dispatch, getState) => {
  if (!hardRefresh) {
    const { profile, bountyBoxesReceivedAt } = getState().entities.user;
    if (!profile.userProfile.externalWalletAddress) return;
    const diffInSeconds = moment().diff(moment(bountyBoxesReceivedAt), "seconds");
    if (diffInSeconds < 30) return;
  }

  return dispatch(
    apiCallBegan({
      url: "bountyBox",
      onStart: bountyBoxesRequested.type,
      onSuccess: bountyBoxesReceived.type,
      onError: bountyBoxesRequestFailed.type,
      callback,
    }),
  );
};
export const loadBountyBoxDetails = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "bountyBox/" + id,
      onStart: bountyBoxDetailsRequested.type,
      onSuccess: bountyBoxDetailsReceived.type,
      onError: bountyBoxDetailsRequestFailed.type,
      callback,
    }),
  );
};
export const redeemBountyBox = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "bountyBox/process/" + id,
      callback,
    }),
  );
};

//  SSO
export const loadGoogleAuthURL = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/google/authURL",
      onStart: googleAuthUrlRequested.type,
      onSuccess: googleAuthUrlReceived.type,
      onError: googleAuthUrlRequestFailed.type,
      callback,
    }),
  );
};
export const authorizeGoogleCode = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/google/authorize",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadFBAuthURL = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/facebook/authURL",
      onStart: fbAuthUrlRequested.type,
      onSuccess: fbAuthUrlReceived.type,
      onError: fbAuthUrlRequestFailed.type,
      callback,
    }),
  );
};
export const authorizeFBCode = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/facebook/authorize",
      method: "POST",
      data,
      callback,
    }),
  );
};

export const loadDiscordAuthURL = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/discord/authURL",
      onStart: discordAuthUrlRequested.type,
      onSuccess: discordAuthUrlReceived.type,
      onError: discordAuthUrlRequestFailed.type,
      callback,
    }),
  );
};
export const authorizeDiscordCode = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "onboarding/discord/authorize",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadRewards = (callback) => (dispatch, getState) => {
  const { lastFetchRewards } = getState().entities.user;
  const diffInMinutes = moment().diff(moment(lastFetchRewards), "minutes");
  if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: "reward",
      onStart: rewardsRequested.type,
      onSuccess: rewardsReceived.type,
      onError: rewardsRequestFailed.type,
      callback,
    }),
  );
};
export const loadDailyGameChallenge = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "dailyGameChallenge",
      onStart: dailyGameChallengeRequested.type,
      onSuccess: dailyGameChallengeReceived.type,
      onError: dailyGameChallengeRequestFailed.type,
      callback,
    }),
  );
};
export const loadDailyGameChallengeEligiblity = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "dailyGameChallenge/checkScore",
      onStart: dailyGameChallengeEligiblityRequested.type,
      onSuccess: dailyGameChallengeEligiblityReceived.type,
      onError: dailyGameChallengeEligiblityRequestFailed.type,
      callback,
    }),
  );
};
export const redeemDailyGameChallengeReward = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "dailyGameChallenge/redeemDailyGameChallengeReward",
      callback,
    }),
  );
};
export const loadDailyStreakRewardStatus = (callback) => (dispatch, getState) => {
  const { lastFetchDailyRewardStatus } = getState().entities.user;
  const diffInMinutes = moment().diff(moment(lastFetchDailyRewardStatus), "minutes");
  if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: "reward/checkDailyRewardStatus",
      onStart: dailyStreakRewardStatusRequested.type,
      onSuccess: dailyStreakRewardStatusReceived.type,
      onError: dailyStreakRewardStatusRequestFailed.type,
      callback,
    }),
  );
};
export const redeemDailyReward = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "reward/redeemDailyReward",
      callback,
    }),
  );
};
export const loadThreeDayStreakRewardStatus = (callback) => (dispatch, getState) => {
  const { lastFetchThreeDayRewardStatus } = getState().entities.user;
  const diffInMinutes = moment().diff(moment(lastFetchThreeDayRewardStatus), "minutes");
  if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: "reward/checkThreeDayStreakRewardStatus",
      onStart: threeDayStreakRewardStatusRequested.type,
      onSuccess: threeDayStreakRewardStatusReceived.type,
      onError: threeDayStreakRewardStatusRequestFailed.type,
      callback,
    }),
  );
};
export const redeemThreeDayStreakReward = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "reward/redeemThreeDayStreakReward",
      callback,
    }),
  );
};
export const loadWeeklyStreakRewardStatus = (callback) => (dispatch, getState) => {
  const { lastFetchWeeklyRewardStatus } = getState().entities.user;
  const diffInMinutes = moment().diff(moment(lastFetchWeeklyRewardStatus), "minutes");
  if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: "reward/checkWeeklyStreakRewardStatus",
      onStart: weeklyStreakRewardStatusRequested.type,
      onSuccess: weeklyStreakRewardStatusReceived.type,
      onError: weeklyStreakRewardStatusRequestFailed.type,
      callback,
    }),
  );
};
export const redeemWeeklyStreakReward = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "reward/redeemWeeklyStreakReward",
      callback,
    }),
  );
};

// Zed Run NFT Color
export const zedRunNftColor = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/zedRunNftColor",
      callback,
    }),
  );
};

// Zed Run NFT check
export const checkZRunNft = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/checkZrunNft",
      onStart: checkzRunNftRequested.type,
      onSuccess: checkzRunNftReceived.type,
      onError: checkzRunNftRequestFailed.type,
      callback,
    }),
  );
};
export const redeemZRunNft = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/redeemZrunNft",
      callback,
    }),
  );
};
export const redeemTickets = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/redeemTickets",
      method: "POST",
      data,
      callback,
    }),
  );
};
//referral
export const createReferralCode = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "referral/code",
      method: "POST",
      data,
      callback,
    }),
  );
};
export const loadReferralCodes = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "referral/code",
      onStart: referralCodesRequested.type,
      onSuccess: referralCodesReceived.type,
      onError: referralCodesRequestFailed.type,
      callback,
    }),
  );
};
export const loadReferralRewards = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "referral/availableRewards",
      onStart: referralRewardsRequested.type,
      onSuccess: referralRewardsReceived.type,
      onError: referralRewardsRequestFailed.type,
      callback,
    }),
  );
};
export const claimReferralReward = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "referral/claimRewards",
      callback,
    }),
  );
};
export const loadSlides = (callback) => (dispatch, getState) => {
  const { lastFetchSlides } = getState().entities.user;
  const diffInMinutes = moment().diff(moment(lastFetchSlides), "minutes");
  if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: "slider",
      onStart: slidesRequested.type,
      onSuccess: slidesReceived.type,
      onError: slidesRequestFailed.type,
      callback,
    }),
  );
};
export const getUser = createSelector(
  (state) => state.entities.user,
  (user) => user,
);
