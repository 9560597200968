import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "practiceGame",
  initialState: {
    error: null,
    loadingGame: false,
    id: null,
    gameStartedAt: null,
    question: null,
    loadingGameQuestion: true,
    submittingAnswer: false,
    answerData: null,
    loadingGameResult: true,
    gameOver: null,
    gameContest: null,
    gameContestLoading: true,
    removeOneLoading: false,
    removed: false,
  },

  reducers: {
    // Requesting a game
    prepareGameRequested: (game, action) => {
      game.loadingGame = true;
      game.id = null;
      game.question = null;
    },
    prepareGameReceived: (game, action) => {
      game.loadingGame = false;
      game.id = action.payload.game;
      game.gameStartedAt = new Date().valueOf();
    },
    prepareGameRequestFailed: (game, action) => {
      game.loadingGame = false;
      game.error = action.payload.message;
    },

    // Requesting a question
    gameQuestionRequested: (game, action) => {
      game.loadingGameQuestion = true;
    },
    gameQuestionReceived: (game, action) => {
      game.loadingGameQuestion = false;
      game.question = action.payload;
    },
    gameQuestionRequestFailed: (game, action) => {
      game.loadingGameQuestion = false;
      game.error = action.payload.message;
      game.question = null;
    },

    // Submitting an answer
    answerSubmitted: (game, action) => {
      game.submittingAnswer = true;
    },
    answerSubmittedSuccess: (game, action) => {
      game.loadingGameQuestion = true;
      game.submittingAnswer = false;
      game.answerData = action.payload;
    },
    answerSubmittedFailed: (game, action) => {
      game.submittingAnswer = false;
      game.error = action.payload.message;
    },

    // Requesting a game result
    gameResultRequested: (game, action) => {
      game.loadingGameResult = true;
      game.id = null;
      game.question = null;
      game.answerData = null;
    },
    gameResultReceived: (game, action) => {
      game.loadingGameResult = false;
      game.gameOver = action.payload;
    },
    gameResultRequestFailed: (game, action) => {
      game.loadingGameResult = false;
      game.error = action.payload.message;
      game.gameOver = null;
    },

    // Requesting a game contest
    gameContestRequested: (game, action) => {
      game.gameContestLoading = true;
    },
    gameContestReceived: (game, action) => {
      game.gameContestLoading = false;
      game.gameContest = action.payload;
    },
    gameContestRequestFailed: (game, action) => {
      game.gameContestLoading = false;
      game.error = action.payload.message;
      game.gameContest = null;
    },

    // Requesting a game contest
    removeOneRequested: (game, action) => {
      game.loading = true;
    },
    removeOneReceived: (game, action) => {
      game.removed = action.payload.removed;
    },
    removeOneRequestFailed: (game, action) => {
      game.loading = false;
      game.error = action.payload.message;
    },
  },
});
export const {
  prepareGameRequested,
  prepareGameReceived,
  prepareGameRequestFailed,
  gameQuestionRequested,
  gameQuestionReceived,
  gameQuestionRequestFailed,
  answerSubmitted,
  answerSubmittedSuccess,
  answerSubmittedFailed,
  gameResultRequested,
  gameResultReceived,
  gameResultRequestFailed,
  gameContestRequested,
  gameContestReceived,
  gameContestRequestFailed,
  removeOneRequested,
  removeOneReceived,
  removeOneRequestFailed,
} = slice.actions;

export default slice.reducer;

// Action Creators
const gameUrl = "gameFree/";

// Action Creators

// Action for requesting a game
export const prepareGame = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "prePareGame",
      onStart: prepareGameRequested.type,
      onSuccess: prepareGameReceived.type,
      onError: prepareGameRequestFailed.type,
      callback,
    }),
  );
};

// Action for remove one
export const removeOne = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: gameUrl + "removeOne",
      onStart: removeOneRequested.type,
      onSuccess: removeOneReceived.type,
      onError: removeOneRequestFailed.type,
      data: data,
      callback,
    }),
  );
};

// Action for contest details
export const getGameContest = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "contest/" + id,
      onStart: gameContestRequested.type,
      onSuccess: gameContestReceived.type,
      onError: gameContestRequestFailed.type,
      callback,
    }),
  );
};

// Action for Game result
export const getGameResult = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: gameUrl + "gameOver",
      onStart: gameResultRequested.type,
      onSuccess: gameResultReceived.type,
      onError: gameResultRequestFailed.type,
      data: data,
      callback,
    }),
  );
};

// Action for requesting a question
export const loadGameQuestion = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: gameUrl + "getQuestion",
      onStart: gameQuestionRequested.type,
      onSuccess: gameQuestionReceived.type,
      onError: gameQuestionRequestFailed.type,
      data: data,
      callback,
    }),
  );
};

// Action for submitting an answer
export const submitAnswer = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: gameUrl + "sendAnswer",
      onStart: answerSubmitted.type,
      onSuccess: answerSubmittedSuccess.type,
      onError: answerSubmittedFailed.type,
      data: data,
      callback,
    }),
  );
};

// Selector
export const getPracticeGame = createSelector(
  (state) => state.entities.practiceGame,
  (practiceGame) => practiceGame,
);
