import React, { Component, Suspense, lazy } from "react";
import { setCookie, getCookie } from "../utils/cookiesUtilities";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import LogRocket from "logrocket";
import { connect } from "react-redux";

import {
  getUser,
  loadProfile,
  signIn,
  checkWallet,
  loadTransactions,
  loadNotifications,
  markNotificationAsRead,
  changeUnreadCount,
  togglePopupRedux,
  toggleHowToPlayRedux,
  toggleDiscordModalRedux,
  loadAvailableTickets,
  togglePurchaseCoinsRedux,
  loadBountyBoxes,
  toggleBountyBoxRedux,
  toggleGoogleAuthUrlLoading,
  toggleFBAuthUrlLoading,
  toggleDiscordAuthUrlLoading,
} from "../store/user";
import moment from "moment";
import { formatDate } from "../utils/dateConversion";
import { withRouter } from "../utils/withRouter";
import mixpanel from "mixpanel-browser";

//  Components
// import ErrorPopup from "./errorPopup";
// import ConnectPopup from "./modals/wallet/connectWallet";
// import HowItWorks from "./howItWorks";
// import NotificationModal from "common/modals/notifications/notification.jsx";
// import PurchaseCoinsModal from "./modals/purchaseCoins/purchaseCoinsModal";
// import OnboardingModal from "./modals/onboarding/onboarding";
// import BountyBoxModal from "./modals/bountyBox/bountyBox";
// import ConnectWalletPopup from "./modals/wallet/connectExternal";

// import NotificationDropdown from "./headerNotification";
import { prepareData } from "./gtmDatalayer";
// import DailyCredit from "./dailyCredits/dailyCredit";
import Button from "./HeaderButton";

const ErrorPopup = lazy(() => import("./errorPopup"));
const ConnectPopup = lazy(() => import("./modals/wallet/connectWallet"));
const HowItWorks = lazy(() => import("./howItWorks"));
const NotificationModal = lazy(() => import("common/modals/notifications/notification.jsx"));
const PurchaseCoinsModal = lazy(() => import("./modals/purchaseCoins/purchaseCoinsModal"));
const OnboardingModal = lazy(() => import("./modals/onboarding/onboarding"));
const BountyBoxModal = lazy(() => import("./modals/bountyBox/bountyBox"));
const ConnectWalletPopup = lazy(() => import("./modals/wallet/connectExternal"));
const NotificationDropdown = lazy(() => import("./headerNotification"));
const DailyCredit = lazy(() => import("./dailyCredits/dailyCredit"));

//  Images
const logo = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/burnghost-logo.svg";
const mobLogo = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/bgNewLogo.svg";
const darkLogo = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/burnghostHorizontal.png";
const notificationUnreadIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/notificationActive.svg";
const userIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/user.png";
const adminIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/admin-icon-01.svg";
const notificationIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/grey-notification-icon.svg";
const chatIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/chat2-icon.svg";
const arcadeCoinIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/header-subtract-icon-2.svg";
const lootCoinIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/header-subtract-icon-1.svg";
const xpIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/xp_noborder.svg";

class Header extends Component {
  state = {
    loading: false,
    showShadow: false,
    showPopupW: false,
    showLoginPopup: false,
    showHowItWorks: false,
    tokenId: 0,
    showDailyStreak: false,
    showNotifications: false,
    loginLoading: false,
    showSignupModal: false,
    isNotificationOpen: false,
    isChatOpen: false,
    showChat: false,
    showNotificationModal: false,
    showBountyBox: false,
    onboardingType: "signup",
    emptyProfiledata: false,
  };

  componentDidMount() {
    let searchParams = new URLSearchParams(this.props.router?.location?.search);
    const referralCode = this.props.router.params.referralCode;
    if (localStorage.getItem("x-auth-token")) {
      const user = JSON.parse(localStorage.getItem("userProfile"));
      if (!user?.userName || !user?.email) {
        this.setState({ emptyProfiledata: true }, () => {
          this.toggleSignup();
        });
        return;
      }
      this.props.loadProfile((res) => {
        if (res.status === 401) window.location.href = "/";
        if (res.status === 200) {
          localStorage.setItem("userProfile", JSON.stringify(res?.data?.userProfile));
          window &&
            window._hsq &&
            window._hsq.push([
              "identify",
              {
                email: res?.data?.userProfile.email,
              },
            ]);
        }
        let lastVisit = getCookie("lastVisit") || false;
        if (lastVisit) lastVisit = JSON.parse(lastVisit);

        if (res?.data?.connectedWallet?.walletAddress) {
          this.props.loadBountyBoxes();
        }
        if (
          !lastVisit ||
          formatDate(moment(), "YYYY-MM-DD") !== lastVisit.date ||
          lastVisit.profile !== res.data.userProfile?.id
        ) {
          if (process.env.REACT_APP_DAILY_STREAK_BONUS_DISPLAY === "1" && localStorage.getItem("welcomePopup")) {
            this.toggleDailyStreak();
          }
          setCookie(
            "lastVisit",
            JSON.stringify({
              date: formatDate(moment(), "YYYY-MM-DD"),
              profile: res.data.userProfile && res.data.userProfile.id,
            }),
          );
        }
        LogRocket.identify(res.data.userProfile?.walletAddress, res.data.userProfile);
      });

      this.props.loadNotifications();
      const purchaseModal = searchParams.get("purchase");
      if (purchaseModal) {
        this.props.togglePurchaseCoinsRedux(true);
      }
    } else if (this.props.router?.location?.search) {
      const code = searchParams.get("code");
      const loginModal = searchParams.get("login");
      const registerModal = searchParams.get("register");

      if (loginModal) {
        this.setState({ onboardingType: "login" }, () => {
          this.toggleSignup();
        });
      } else if (registerModal) {
        this.setState({ onboardingType: "signup" }, () => {
          this.toggleSignup();
        });
      }
      if (code) {
        if (localStorage.getItem("ssoAuth") === "google") {
          this.props.toggleGoogleAuthUrlLoading(true);
        } else if (localStorage.getItem("ssoAuth") === "facebook") {
          this.props.toggleFBAuthUrlLoading(true);
        } else if (localStorage.getItem("ssoAuth") === "discord") {
          this.props.toggleDiscordAuthUrlLoading(true);
        }
        this.toggleSignup();
      }
    } else if (referralCode) {
      localStorage.setItem("referralCode", referralCode);
      this.setState({ onboardingType: "signup" }, () => {
        this.toggleSignup();
      });
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.getUser.profileLoaded && this.props.getUser.profileLoaded !== prevProps.getUser.profileLoaded) {
      this.props.loadAvailableTickets(() => {});
    }
    const unreadNotifications = this.props.getUser?.notifications?.filter(
      (notif) => !notif.isRead && (notif.type === "AWARDED" || notif?.type === "WINNER") && notif?.topic !== "LootBox",
    );
    if (
      unreadNotifications?.length > 0 &&
      !this.state.showNotificationModal &&
      this.props.getUser.unreadCount > 0 &&
      this.props.page === "home" &&
      !localStorage.getItem("notificationOpen")
    ) {
      localStorage.setItem("notificationOpen", true);
      this.toggleNotification(null, true);
    }

    if (this.props.toggleSignUpFromSlider && this.props.toggleSignUpFromSlider !== prevProps.toggleSignUpFromSlider)
      this.toggleSignup();
    if (this.props.toggleSignUpFromSlider && this.state.showSignupModal !== prevState.showSignupModal) {
      this.props.setToggle();
    }
    if (this.props.router.params.referralCode && window.location.pathname !== "/")
      this.props.router.navigate("/", { replace: true });
  };

  toggleChatModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showChat: !this.state.showChat });
  };

  toggleNotification = (e, val) => {
    if (e) e.preventDefault();
    this.setState({
      showNotificationModal: val ?? !this.state.showNotificationModal,
    });
  };

  closeLoginPopup = () => this.setState({ showLoginPopup: false });

  openLoginPopup = () => this.setState({ showLoginPopup: true });
  //  Toggle Methods for onboarding flow
  toggleSignup = (e) => {
    if (e) e.preventDefault();
    if (this.state.showSignupModal) {
      this.setState({ onboardingType: "signup" });
    }
    this.setState({ showSignupModal: !this.state.showSignupModal });
  };

  togglePopupW = (e) => {
    this.setState({
      showPopupW: !this.state.showPopupW,
    });
  };

  toggleHowItWorks = (e) => {
    if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("How to play modal_viewed ");

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(
      prepareData({
        event: "header_clicks", // fixed value
        click_text: "How to play", // dynamic value
      }),
    );
    if (e) e.preventDefault();
    this.setState({
      showHowItWorks: !this.state.showHowItWorks,
    });
  };

  toggleDailyStreak = (e) => {
    if (e) e.preventDefault();
    this.setState({
      showDailyStreak: !this.state.showDailyStreak,
    });
  };

  componentWillUnmount = () => {
    localStorage.removeItem("notificationOpen");
  };
  disconnect = async () => {
    try {
      this.props.web3Functions.deactivate();
      window.location.href = "/";
    } catch (error) {}
  };

  render() {
    const { userProfile, connectedWallet } = this.props.getUser.profile;
    const { notifications, unreadCount, profileLoaded, availableTickets, bountyBoxesTotal, bountyBoxLoading } =
      this.props.getUser;
    // const profileLoaded = false;
    return (
      <>
        <header id="newHeader" className={`${window.scrollY > 0 && "fixed"}`}>
          <nav className="navbar navbar-expand-xl">
            <div
              className={
                this.props.type === "newHeader"
                  ? "container-fluid"
                  : "container" + (this.props.type === "crashGame" ? " crash-game-container" : "")
              }
              style={this.props.type === "crashGame" ? { width: "100%", paddingRight: "0px" } : {}}
            >
              <div className="navbar-inside w-100 d-flex align-items-center justify-content-end">
                {!this.props.crashGame && (
                  <>
                    <div className="d-none d-xl-block navbar-brand-wrap">
                      <Link className="navbar-brand" to="/">
                        <img
                          className="desktop-logo d-none d-xxl-block"
                          src={this.props.themeMode === "dark" ? darkLogo : logo}
                          width={241}
                          height={47}
                          alt="logo"
                        />
                        <img className="d-block d-xxl-none" src={mobLogo} width={105} height={147} alt="" />
                      </Link>
                    </div>
                    {profileLoaded && _.isEmpty(userProfile) && (
                      <>
                        <div className="mob-logo-area d-none d-sm-block d-xl-none">
                          <Link className="navbar-brand" to="/">
                            <img className="w-100 h-100" src={darkLogo} width={241} height={47} alt="logo" />
                          </Link>
                        </div>
                        <div className="mob-logo-area-1 mob-logo-area d-sm-none d-xl-none">
                          <Link className="navbar-brand" to="/">
                            <img className="w-100 h-100" src={mobLogo} width={105} height={147} alt="" />
                          </Link>
                        </div>
                      </>
                    )}
                    {profileLoaded && !_.isEmpty(userProfile) && (
                      <div className="mob-logo-area-1 mob-logo-area d-none d-sm-block d-xl-none">
                        <Link className="navbar-brand" to="/">
                          <img className="w-100 h-100" src={mobLogo} width={105} height={147} alt="" />
                        </Link>
                      </div>
                    )}
                  </>
                )}
                <button
                  className={"navbar-toggler right-align collapsed" + (this.props.crashGame ? "" : " left-13px")}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#mainNav"
                  aria-controls="mainNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-xl-end" id="mainNav">
                  <div className="navbar-inside">
                    <div className="full-navbar-inside w-100 position-relative">
                      <button
                        className="navbar-toggler right-align collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#mainNav"
                        aria-controls="mainNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>

                      {!_.isEmpty(userProfile) ? (
                        <>
                          <div className="user-new-conetnt d-flex align-items-center d-xl-none">
                            <div
                              className="user-new-box"
                              onClick={(e) => {
                                if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("View profile");
                                this.props.router.navigate("/account");
                              }}
                            >
                              <img
                                src={
                                  userProfile.image ? process.env.REACT_APP_CDNURL + userProfile.image.small : userIcon
                                }
                                alt=""
                              />
                            </div>
                            <div
                              className="user-text-box fw-semibold"
                              onClick={(e) => {
                                if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("View profile");
                                this.props.router.navigate("/account");
                              }}
                            >
                              {userProfile?.userName}
                            </div>
                          </div>
                          <div className="user-creadit-block rounded-pill d-xl-none justify-content-start inside-coins-box">
                            <div className="widget-icon-block d-flex flex-wrap align-items-center">
                              <div className="widget-icon">
                                <img src={arcadeCoinIcon} alt="" />
                              </div>
                              <div className="widget-icon-label hcw-count-box">
                                {userProfile?.arcadeCoins?.toLocaleString("en-us") ?? 0}
                              </div>
                            </div>
                            <div className="widget-icon-block d-flex flex-wrap align-items-center">
                              <div className="widget-icon">
                                <img src={lootCoinIcon} alt="" />
                              </div>
                              <div className="widget-icon-label hcw-count-box">
                                {userProfile?.lootBonusCoins?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) ?? 0}
                              </div>
                            </div>
                            <div
                              className="hcw-credit-icon d-flex align-items-center justify-content-center"
                              style={{ width: 25 }}
                            >
                              <img src={xpIcon} alt="" />
                            </div>
                            <div className="hcw-count-box">{userProfile?.xP?.toLocaleString("en-us") ?? 0}</div>
                          </div>
                        </>
                      ) : (
                        <img
                          className="xl-desktop-logo d-xl-none"
                          src={darkLogo}
                          width={241}
                          height={47}
                          alt="logo"
                          onClick={(e) => {
                            e.preventDefault();
                            // Navigate to home page
                            this.props.router.navigate("/");
                          }}
                        />
                      )}

                      <ul className="navbar-nav">
                        <li className="nav-item d-xl-none">
                          <Link className={`nav-link text-uppercase ${this.props.page === "home" && "active"}`} to="/">
                            Home
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={`nav-link text-uppercase ${this.props.page === "contests" && "active"}`}
                            onClick={(e) => {
                              window.dataLayer = window.dataLayer || [];
                              window.dataLayer.push(
                                prepareData({
                                  event: "header_clicks", // fixed value
                                  click_text: "Contests", // dynamic value
                                }),
                              );
                            }}
                            to="/contests"
                          >
                            Contests
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link
                            className={`nav-link text-uppercase ${this.props.page === "prizePool" && "active"}`}
                            onClick={(e) => {
                              window.dataLayer = window.dataLayer || [];
                              window.dataLayer.push(
                                prepareData({
                                  event: "header_clicks", // fixed value
                                  click_text: "Prizes", // dynamic value
                                }),
                              );
                              if (process.env.REACT_APP_MIXPANEL === "1") {
                                mixpanel?.track("View all sweepstakes/prizes");
                                // mixpanel?.track("View active sweepstakes cards");
                              }
                            }}
                            to="/prizes"
                          >
                            Prizes
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link
                            className={`nav-link text-uppercase ${this.props.page === "winners" && "active"}`}
                            onClick={(e) => {
                              window.dataLayer = window.dataLayer || [];
                              window.dataLayer.push(
                                prepareData({
                                  event: "header_clicks", // fixed value
                                  click_text: "Winners", // dynamic value
                                }),
                              );
                            }}
                            to="/winners"
                          >
                            Winners
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link
                            className={`nav-link text-uppercase ${this.props.page === "leaderboard" && "active"}`}
                            onClick={(e) => {
                              window.dataLayer = window.dataLayer || [];
                              window.dataLayer.push(
                                prepareData({
                                  event: "header_clicks", // fixed value
                                  click_text: "Leaderboard", // dynamic value
                                }),
                              );
                            }}
                            to="/leaderboard"
                          >
                            Leaderboard
                          </Link>
                        </li> */}
                        {!this.props.crashGame && (
                          <li className="nav-item">
                            <a className="nav-link text-uppercase" href="#!" onClick={this.toggleHowItWorks}>
                              How to Play
                            </a>
                          </li>
                        )}
                        {profileLoaded && _.isEmpty(userProfile) && (
                          <li className="nav-item ">
                            <a
                              className="nav-link text-uppercase bold"
                              style={{ color: "white" }}
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ onboardingType: "login" }, this.toggleSignup);
                              }}
                            >
                              <strong>Sign In</strong>
                            </a>
                          </li>
                        )}
                        {!_.isEmpty(userProfile) && (
                          <>
                            <li className="nav-item d-xl-none">
                              <div className=" dropdown notify-dropdown">
                                <button
                                  type="button"
                                  aria-label="Notifications"
                                  className="nav-link dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={() => {
                                    if (process.env.REACT_APP_MIXPANEL === "1") {
                                      mixpanel?.track("Notification Clicked");
                                      mixpanel?.track("View notifications");
                                      mixpanel?.track("Notification Opened");
                                    }
                                    this.setState(
                                      {
                                        isNotificationOpen: !this.state.isNotificationOpen,
                                      },
                                      () => {
                                        window.dataLayer = window.dataLayer || [];
                                        window.dataLayer.push(
                                          prepareData({
                                            event: "notifications", // fixed value
                                            interaction_type: this.state.isNotificationOpen ? "open" : "close", // open or close
                                          }),
                                        );
                                      },
                                    );

                                    const data = {
                                      ids: notifications.map((n) => n.id),
                                    };
                                    if (data.ids.length > 0) {
                                      this.props.markNotificationAsRead(data, () => {
                                        this.props.changeUnreadCount();
                                      });
                                    }
                                  }}
                                >
                                  Notifications
                                  {unreadCount > 0 && <span className="notify-count-header ">{unreadCount}</span>}
                                </button>
                                <Suspense fallback={<></>}>
                                  <NotificationDropdown
                                    unreadCount={unreadCount}
                                    notifications={notifications}
                                    navigate={this.props.router.navigate}
                                    themeMode={this.props.themeMode}
                                  />
                                </Suspense>
                              </div>
                            </li>
                            <li className="nav-item d-xl-none">
                              <div className=" dropdown notify-dropdown">
                                <button
                                  type="button"
                                  className="nav-link dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      isChatOpen: !this.state.isChatOpen,
                                    });
                                    this.props.toggleDiscordModalRedux(!this.props.getUser.showDiscordModal);
                                  }}
                                >
                                  Chat
                                </button>
                              </div>
                            </li>
                          </>
                        )}
                      </ul>
                      <div className="navbar-bottom-widget d-xl-none">
                        <div className="nbw-social-links d-flex flex-wrap">
                          <div className="nbw-item">
                            <a
                              href="https://discord.gg/burnghost"
                              target="_blank"
                              rel="noreferrer"
                              className="nbw-box text-uppercase w-100 d-flex flex-column align-items-center"
                            >
                              <i className="fab fa-discord"></i>
                              DISCORD
                            </a>
                          </div>
                          <div className="nbw-item">
                            <a
                              href="https://twitter.com/burnghostgames"
                              target="_blank"
                              className="nbw-box text-uppercase w-100 d-flex flex-column align-items-center"
                              rel="noreferrer"
                            >
                              <i className="fa-brands fa-x-twitter"></i>
                              TWitter
                            </a>
                          </div>
                          <div className="nbw-item">
                            <a
                              href="https://www.instagram.com/burnghostgames/"
                              target="_blank"
                              className="nbw-box text-uppercase w-100 d-flex flex-column align-items-center"
                              rel="noreferrer"
                            >
                              <i className="fab fa-instagram"></i>
                              Instagram
                            </a>
                          </div>
                        </div>
                        {!profileLoaded || bountyBoxLoading ? (
                          <Skeleton width="100%" height={50} />
                        ) : _.isEmpty(userProfile) ? (
                          <>
                            <Button
                              title="REGISTER"
                              classes="btn btn-gradient-default btn-block common-btn-gradient"
                              onClick={(e) => {
                                e.preventDefault();
                                if (process.env.REACT_APP_GA === "1") {
                                  window.dataLayer = window.dataLayer || [];
                                  window.dataLayer.push(
                                    prepareData({
                                      event: "RegisterButton_clicked",
                                    }),
                                  );
                                }
                                if (process.env.REACT_APP_MIXPANEL === "1") {
                                  mixpanel?.track("RegisterButton_clicked");
                                }
                                this.toggleSignup();
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {bountyBoxLoading ? (
                              <Skeleton width="100%" height={50} />
                            ) : bountyBoxesTotal?.bountyBox > 0 ? (
                              <Button
                                title="REDEEM"
                                classes="btn btn-gradient-default btn-block common-btn-gradient"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("Redeem_clicked");
                                  this.props.toggleBountyBoxRedux(true);
                                }}
                              />
                            ) : (
                              <Button
                                title="PURCHASE"
                                classes="btn btn-gradient-default btn-block common-btn-gradient"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.togglePurchaseCoinsRedux(true);
                                  if (process.env.REACT_APP_MIXPANEL === "1") {
                                    mixpanel?.track("Purchase Coins");
                                  }
                                }}
                              />
                            )}
                          </>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`header-right-content d-flex flex-wrap align-items-center ${
                    _.isEmpty(userProfile) && "ms-auto"
                  }`}
                >
                  {!userProfile.arcadeCoins && !profileLoaded && window.innerWidth > 767.98 ? (
                    <Skeleton width={49} height={49} circle />
                  ) : (
                    !_.isEmpty(userProfile) && (
                      <div className="hrc-item dropdown notify-dropdown d-none d-md-block">
                        <button
                          type="button"
                          aria-label="Notifications"
                          className=" header-notify-widget rounded-circle d-flex flex-wrap align-items-center justify-content-center dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => {
                            if (process.env.REACT_APP_MIXPANEL === "1") {
                              mixpanel?.track("Notification Clicked");
                              mixpanel?.track("View notifications");
                              mixpanel?.track("Notification Opened");
                            }
                            this.setState(
                              {
                                isNotificationOpen: !this.state.isNotificationOpen,
                              },
                              () => {
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push(
                                  prepareData({
                                    event: "notifications", // fixed value
                                    interaction_type: this.state.isNotificationOpen ? "open" : "close", // open or close
                                  }),
                                );
                              },
                            );

                            const data = {
                              ids: notifications.map((n) => n.id),
                            };
                            if (data.ids.length > 0) {
                              this.props.markNotificationAsRead(data, () => {
                                this.props.changeUnreadCount();
                              });
                            }
                          }}
                        >
                          <img src={unreadCount > 0 ? notificationUnreadIcon : notificationIcon} alt="" />
                        </button>
                        <Suspense fallback={<></>}>
                          <NotificationDropdown
                            unreadCount={unreadCount}
                            notifications={notifications}
                            navigate={this.props.router.navigate}
                            themeMode={this.props.themeMode}
                          />
                        </Suspense>
                      </div>
                    )
                  )}
                  {!userProfile.arcadeCoins &&
                  !profileLoaded &&
                  window.innerWidth > 767.98 &&
                  window.innerWidth <= 1280.98 ? (
                    <Skeleton width={49} style={{ marginLeft: 20 }} height={49} circle />
                  ) : (
                    !_.isEmpty(userProfile) &&
                    window.innerWidth <= 1280.98 && (
                      <div className="hrc-item">
                        <button
                          type="button"
                          className="d-none d-md-flex chat-toggler header-notify-widget rounded-circle flex-wrap align-items-center justify-content-center"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.toggleDiscordModalRedux(!this.props.getUser.showDiscordModal);
                          }}
                        >
                          <img src={chatIcon} alt="" />
                        </button>
                      </div>
                    )
                  )}
                  {!userProfile.arcadeCoins && (!profileLoaded || bountyBoxLoading) ? (
                    <div className="hrc-item d-none d-xl-block ">
                      <Skeleton width={137} height={49} borderRadius={50} containerClassName="d-block lh-1" />
                    </div>
                  ) : _.isEmpty(userProfile) ? (
                    <div className="hrc-item d-none d-xl-block ">
                      <Button
                        title="REGISTER"
                        classes="header-hero-btn common-btn-gradient register-btn"
                        icon={adminIcon}
                        iconWidth={23}
                        iconHeight={24}
                        onClick={(e) => {
                          e.preventDefault();
                          if (process.env.REACT_APP_GA === "1") {
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push(
                              prepareData({
                                event: "RegisterButton_clicked",
                              }),
                            );
                          }
                          if (process.env.REACT_APP_MIXPANEL === "1") {
                            mixpanel?.track("RegisterButton_clicked");
                          }
                          this.toggleSignup();
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="hrc-item d-none d-xl-block ">
                        {!userProfile.arcadeCoins && bountyBoxLoading ? (
                          <Skeleton width="100%" height={49} />
                        ) : bountyBoxesTotal?.bountyBox > 0 ? (
                          <Button
                            // iconLeft={redeemIcon}
                            title="REDEEM"
                            classes="header-hero-btn common-btn-gradient hrp-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("Redeem_clicked");
                              this.props.toggleBountyBoxRedux(true);
                            }}
                          />
                        ) : (
                          <Button
                            title="PURCHASE"
                            classes="header-hero-btn common-btn-gradient"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.togglePurchaseCoinsRedux(true);
                              if (process.env.REACT_APP_MIXPANEL === "1") {
                                mixpanel?.track("Purchase Coins");
                              }
                            }}
                          />
                        )}
                      </div>
                    </>
                  )}
                  {!userProfile.arcadeCoins && !profileLoaded ? (
                    <Skeleton width={250} height={49} style={{ marginLeft: 20 }} />
                  ) : (
                    !_.isEmpty(userProfile) && (
                      <div
                        className="hrc-item header-credit-widget rounded-pill d-flex flex-wrap align-items-center pointer"
                        onClick={(e) => {
                          if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("View profile");
                          this.props.router.navigate("/account");
                        }}
                      >
                        <div className="widget-icon-block d-flex flex-wrap align-items-center">
                          <div className="widget-icon">
                            <img src={arcadeCoinIcon} alt="" />
                          </div>
                          <div className="widget-icon-label hcw-count-box">
                            {userProfile?.arcadeCoins?.toLocaleString("en-us") ?? 0}
                          </div>
                        </div>
                        <div className="widget-icon-block d-flex flex-wrap align-items-center">
                          <div className="widget-icon">
                            <img src={lootCoinIcon} alt="" />
                          </div>
                          <div className="widget-icon-label hcw-count-box">
                            {userProfile?.lootBonusCoins?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) ?? 0}
                          </div>
                        </div>
                        <div
                          className="hcw-credit-icon d-flex align-items-center justify-content-center"
                          style={{ width: 25 }}
                        >
                          <img src={xpIcon} alt="" />
                        </div>
                        <div className="hcw-count-box">{userProfile?.xP?.toLocaleString("en-us") ?? 0}</div>
                        <div className="hcw-profile-box d-flex align-items-center justify-content-center ">
                          <img
                            src={userProfile.image ? process.env.REACT_APP_CDNURL + userProfile.image.small : userIcon}
                            alt=""
                          />
                        </div>
                      </div>
                    )
                  )}

                  {_.isEmpty(userProfile) ? (
                    <div className="hrc-item d-block d-xl-none">
                      {!profileLoaded ? (
                        <Skeleton width="100%" height={50} />
                      ) : (
                        <div className="d-flex gap-4">
                          <button
                            type="button"
                            className="signup-page-link"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ onboardingType: "login" }, this.toggleSignup);
                            }}
                          >
                            Sign in
                          </button>
                          <Button
                            title="REGISTER"
                            classes="header-hero-btn common-btn-gradient"
                            icon={adminIcon}
                            iconWidth={23}
                            iconHeight={24}
                            onClick={(e) => {
                              e.preventDefault();
                              if (process.env.REACT_APP_GA === "1") {
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push(
                                  prepareData({
                                    event: "RegisterButton_clicked",
                                  }),
                                );
                              }
                              if (process.env.REACT_APP_MIXPANEL === "1") {
                                mixpanel?.track("RegisterButton_clicked");
                              }
                              this.toggleSignup();
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </nav>
        </header>
        <Suspense fallback={<></>}>
          <ErrorPopup setError={this.setError} error={this.state.error} title={"CONNECT TO <span>BURN GHOST</span>"} />
        </Suspense>
        <Suspense fallback={<></>}>
          <NotificationModal
            showModal={this.state.showNotificationModal}
            unreadNotifications={notifications?.filter(
              (notif) =>
                !notif.isRead && (notif.type === "AWARDED" || notif?.type === "WINNER") && notif?.topic !== "LootBox",
            )}
            toggleModal={this.toggleNotification}
          />
        </Suspense>
        <Suspense fallback={<></>}>
          <HowItWorks
            showModal={this.props.getUser.showHowToPlay}
            toggleModal={() => this.props.toggleHowToPlayRedux()}
            type="onboarding"
          />
        </Suspense>
        <Suspense fallback={<></>}>
          <HowItWorks showModal={this.state.showHowItWorks} toggleModal={this.toggleHowItWorks} type="header" />
        </Suspense>
        <Suspense fallback={<></>}>
          <ConnectPopup
            togglePopup={this.closeLoginPopup}
            showPopup={this.state.showLoginPopup}
            loginLoading={this.state.loginLoading}
            setLoginLoading={(e) => this.setState({ showLoginPopup: !this.state.showLoginPopup })}
            type="connect"
            openCredits={true}
          />{" "}
        </Suspense>
        <Suspense fallback={<></>}>
          <BountyBoxModal />
        </Suspense>
        <Suspense fallback={<></>}>
          <ConnectWalletPopup />
        </Suspense>
        {/* Sign up flow modals */}
        <Suspense fallback={<></>}>
          <OnboardingModal
            showModal={this.state.showSignupModal}
            toggleModal={this.toggleSignup}
            onboardingType={this.state.onboardingType ?? "signup"}
            showConnect={false}
            emptyProfiledata={this.state.emptyProfiledata}
          />
        </Suspense>
        <Suspense fallback={<></>}>
          <PurchaseCoinsModal />
        </Suspense>
        <Suspense fallback={<></>}>
          <DailyCredit />
        </Suspense>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (data, callback) => dispatch(signIn(data, callback)),
  loadProfile: (callback) => dispatch(loadProfile(callback)),
  loadAvailableTickets: (callback) => dispatch(loadAvailableTickets(callback)),
  loadBountyBoxes: (callback) => dispatch(loadBountyBoxes(callback)),
  loadTransactions: () => dispatch(loadTransactions()),
  loadNotifications: (callback) => dispatch(loadNotifications(callback)),
  checkWallet: (data, callback) => dispatch(checkWallet(data, callback)),
  changeUnreadCount: (data) => dispatch(changeUnreadCount(data)),
  markNotificationAsRead: (data, callback) => dispatch(markNotificationAsRead(data, callback)),
  toggleHowToPlayRedux: (data) => dispatch(toggleHowToPlayRedux(data)),
  toggleDiscordModalRedux: (data) => dispatch(toggleDiscordModalRedux(data)),
  togglePopupRedux: (data) => dispatch(togglePopupRedux(data)),
  togglePurchaseCoinsRedux: (data) => dispatch(togglePurchaseCoinsRedux(data)),
  toggleBountyBoxRedux: (data) => dispatch(toggleBountyBoxRedux(data)),
  toggleFBAuthUrlLoading: (data) => dispatch(toggleFBAuthUrlLoading(data)),
  toggleGoogleAuthUrlLoading: (data) => dispatch(toggleGoogleAuthUrlLoading(data)),
  toggleDiscordAuthUrlLoading: (data) => dispatch(toggleDiscordAuthUrlLoading(data)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
