import React from "react";
const closeBtn = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/cross.svg";

const AlertClose = () => {
  return (
    <div className="alert-close" data-bs-dismiss="alert" aria-label="Close">
      <img src={closeBtn} alt="" />
    </div>
  );
};

export default AlertClose;
