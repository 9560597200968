import WidgetBot from "@widgetbot/react-embed";
import { connect } from "react-redux";
import { getUser } from "../../store/user";
import NonLoggedInPlaceholder from "./nonLoggedInPlaceholder";

function DiscordChatModal(props) {
  const onAPI = (api) => {
    api.on("signIn", (user) => {});
  };
  const url = new URL(process.env.REACT_APP_SITE_URL).hostname;
  const parts = url.split(".");
  const server = parts[0];
  const isLoggedIn = localStorage.getItem("x-auth-token");

  return (
    <>
      {!isLoggedIn ? (
        <NonLoggedInPlaceholder />
      ) : (
        <>
          {server === "dev" || server === "staging" || server === "demo" ? (
            <WidgetBot
              server={process.env.REACT_APP_DISCORD_SERVERID}
              channel={process.env.REACT_APP_DISCORD_CHANNELID}
              onAPI={onAPI}
              height="100%"
              width="100%"
              className="discord-widget"
            />
          ) : (
            <WidgetBot
              server={process.env.REACT_APP_DISCORD_SERVERID}
              channel={process.env.REACT_APP_DISCORD_CHANNELID}
              onAPI={onAPI}
              height="100%"
              width="100%"
              className="discord-widget"
              shard="https://emerald.widgetbot.io"
            />
          )}
        </>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default connect(mapStateToProps, null)(DiscordChatModal);
