import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getUser,
  loadGoogleAuthURL,
  authorizeGoogleCode,
  toggleGoogleAuthUrlLoading,
  loadProfile,
  loadBountyBoxes,
  loadFBAuthURL,
  authorizeFBCode,
  toggleFBAuthUrlLoading,
  loadDiscordAuthURL,
  authorizeDiscordCode,
  toggleDiscordAuthUrlLoading,
  toggleHTPRedux,
  loadWalletMessageToSign,
} from "store/user";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setToken } from "utils/localStorageServices";
import mixpanel from "mixpanel-browser";
import { getCookie } from "utils/cookiesUtilities";

//  Components
import ErrorPopup from "../../errorPopup";
import { prepareData } from "common/gtmDatalayer";
import { verisoulSessionId } from "utils/verisoulUtils";

//  Images
const googleIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/google-icon__1.svg";
const fbIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/facebook__logo__1.svg";
const wallet1 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/t-mode-2.svg";
const discordIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/discord.svg";
const emailIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/email-icon__91.svg";
const loaderIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/loader-svg.svg";
const closeIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/close-icon.svg";
const bgLogo = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/bgNewLogo.svg";
const OnboardingOptions = ({
  setTab,
  loadGoogleAuthURL,
  getUser,
  authorizeGoogleCode,
  toggleGoogleAuthUrlLoading,
  toggleModal,
  loadProfile,
  loadBountyBoxes,
  loadFBAuthURL,
  authorizeFBCode,
  toggleFBAuthUrlLoading,
  loadDiscordAuthURL,
  authorizeDiscordCode,
  toggleDiscordAuthUrlLoading,
  toggleHTPRedux,
  setNewAcc,
  onboardingType,
  loadWalletMessageToSign,
}) => {
  const [showError, setShowError] = useState(false);
  const [showErrorTitle, setShowErrorTitle] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get("code");

    const fn = (event) => {
      if (code) {
        (async () => {
          const sessionId = await verisoulSessionId();
          const referralCode = localStorage.getItem("referralCode");
          const context = {
            hutk: getCookie("hubspotutk"),
            pageUri: window?.location?.host,
            pageName: document?.title,
          };
          const payload = { code, sessionId, context };
          if (referralCode) {
            payload.referralCode = referralCode;
          }
          if (localStorage.getItem("ssoAuth") === "google") {
            authorizeGoogleCode(payload, callback);
          } else if (localStorage.getItem("ssoAuth") === "facebook") {
            authorizeFBCode(payload, callback);
          } else if (localStorage.getItem("ssoAuth") === "discord") {
            authorizeDiscordCode(payload, callback);
          } else {
            window.location.href = "/";
          }
        })();
      }
    };
    window.addEventListener("load", fn);
    return () => window.removeEventListener("load", fn);
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_MIXPANEL === "1") {
      mixpanel?.track("View SSO form");
    }
    if (process.env.REACT_APP_GA === "1") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(
        prepareData({
          event: "View SSO form",
        }),
      );
    }
  }, []);

  const callback = (loginRes) => {
    toggleGoogleAuthUrlLoading(false);
    toggleFBAuthUrlLoading(false);
    toggleDiscordAuthUrlLoading(false);
    const sssoType = localStorage.getItem("ssoAuth");
    if (loginRes.status === 200) {
      localStorage.setItem("userProfile", JSON.stringify(loginRes.data.userProfile));
      setToken(false, loginRes.data.jwtToken);
      if (loginRes.data.userProfile?.userName && loginRes.data.userProfile?.email) {
        if (process.env.REACT_APP_MIXPANEL === "1") {
          mixpanel?.track("Login", { Type: localStorage.getItem("ssoAuth") });
          mixpanel?.identify(loginRes?.data?.userProfile?.id);
        }
        loadProfile((res) => {
          localStorage.setItem("userProfile", JSON.stringify(loginRes?.data?.userProfile));

          if (res?.data?.connectedWallet?.walletAddress) {
            loadBountyBoxes();
          }
        });
        toggleModal();
      } else {
        if (loginRes?.data?.new) {
          if (process.env.REACT_APP_MIXPANEL === "1") {
            mixpanel?.track("Create Account", { Type: localStorage.getItem("ssoAuth") });
            mixpanel?.track(
              sssoType === "google"
                ? "Create account_google"
                : sssoType === "discord"
                ? "Create account_Discord"
                : "Create account_FB",
            );
            mixpanel?.identify(loginRes?.data?.userProfile?.id);
          }
          if (process.env.REACT_APP_GA === "1") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(
              prepareData({
                event: "Create Account",
              }),
            );
            window.dataLayer.push(
              prepareData({
                event:
                  sssoType === "google"
                    ? "Create account_google"
                    : sssoType === "discord"
                    ? "Create account_Discord"
                    : "Create account_FB",
              }),
            );
          }
          toggleHTPRedux(true);
          // if (loginRes?.data?.userProfile?.arcadeCoins > 0 || loginRes?.data?.userProfile?.lootBonusCoins > 0)
          setNewAcc(true);
        }
        setTab(3);
      }
      navigate("/");
    } else {
      if (process.env.REACT_APP_GA === "1") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
          prepareData({
            event: "Create Account_failure",
          }),
        );
        window.dataLayer.push(
          prepareData({
            event:
              sssoType === "google"
                ? "Create account_google_failure"
                : sssoType === "discord"
                ? "Create account_discord_failure"
                : "Create account_fb_failure",
          }),
        );
      }
      if (process.env.REACT_APP_MIXPANEL === "1") {
        mixpanel?.track(
          sssoType === "google"
            ? "Create account_google_failure"
            : sssoType === "discord"
            ? "Create account_discord_failure"
            : "Create account_fb_failure",
        );
        mixpanel?.track("create account_failure");
      }
      if (loginRes?.data?.message === "Email already exists") {
        setShowErrorTitle("Email is already in use.");
        setShowError(
          `It appears that the email provided is already associated with an existing account. Please try signing in with your credentials.\nIf you're encountering issues, you might consider resetting the password or contacting support for further assistance.`,
        );
      } else {
        setShowError(loginRes?.data?.message ?? "Something went wrong");
        setShowErrorTitle("Something went wrong");
      }
      navigate("/");
    }
  };
  const disabledOptions = getUser.googleAuthURLLoading || getUser.fbAuthURLLoading || getUser.discordAuthURLLoading;
  return (
    <>
      <div
        className="modal-close-btn position-absolute d-flex"
        data-bs-dismiss="modal"
        onClick={(e) => {
          toggleFBAuthUrlLoading(false);
          toggleGoogleAuthUrlLoading(false);
          toggleDiscordAuthUrlLoading(false);

          toggleModal(e);
        }}
      >
        <img src={closeIcon} alt="" />
      </div>
      <div className="content-modal-box hide-scrollbar position-relative text-center">
        <div className="content-modal-header">
          <img className="content-modal-logo" src={bgLogo} alt="" />
          <h5>{onboardingType === "login" ? "Welcome Back!" : "Create Account"}</h5>
        </div>
        <div className="form-group">
          <div className="fg-radio-group position-relative">
            <div className="form-check-group-outer">
              <div className="form-check position-relative d-flex">
                <input
                  className="form-check-input"
                  type="radio"
                  name="walletRadio"
                  id="flexRadioDefault1"
                  checked={getUser.googleAuthURLLoading}
                  disabled={disabledOptions}
                  onClick={(e) => {
                    if (process.env.REACT_APP_GA === "1") {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push(
                        prepareData({
                          event: "SignupGoogle_clicked",
                        }),
                      );
                    }
                    if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("SignupGoogle_clicked");
                    loadGoogleAuthURL((res) => {
                      if (res.status === 200) {
                        window.location.href = res.data;
                      }
                    });
                    localStorage.setItem("ssoAuth", "google");
                  }}
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  <img className="wallet-icon small-wallet-icon" src={googleIcon} alt="" />
                  <h5>{onboardingType === "login" ? "Sign in with Google" : "Sign up with Google"}</h5>
                  <p>
                    {getUser.googleAuthURLLoading
                      ? "Authenticating with Google"
                      : onboardingType === "login"
                      ? "Existing Account"
                      : "Create Account"}
                  </p>
                </label>
                <img className="loading__icon fa-spin" src={loaderIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="fg-radio-group position-relative">
            <div className="form-check-group-outer">
              <div className="form-check position-relative d-flex">
                <input
                  className="form-check-input"
                  type="radio"
                  name="walletRadio"
                  id="flexRadioDefault2"
                  checked={getUser.discordAuthURLLoading}
                  disabled={disabledOptions}
                  onClick={(e) => {
                    if (process.env.REACT_APP_GA === "1") {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push(
                        prepareData({
                          event: "SignupDiscord_clicked",
                        }),
                      );
                    }
                    if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("SignupDiscord_clicked");
                    loadDiscordAuthURL((res) => {
                      if (res.status === 200) {
                        window.location.href = res.data;
                      }
                    });
                    localStorage.setItem("ssoAuth", "discord");
                  }}
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  <img className="wallet-icon small-wallet-icon" src={discordIcon} alt="" />
                  <h5>{onboardingType === "login" ? "Sign in with Discord" : "Sign up with Discord"}</h5>
                  <p>
                    {getUser.discordAuthURLLoading
                      ? "Authenticating with Discord"
                      : onboardingType === "login"
                      ? "Existing Account"
                      : "Create Account"}
                  </p>
                </label>
                <img className="loading__icon fa-spin" src={loaderIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="fg-radio-group position-relative">
            <div className="form-check-group-outer">
              <div className="form-check position-relative d-flex">
                <input
                  className="form-check-input"
                  type="radio"
                  name="walletRadio"
                  id="flexRadioDefault2"
                  checked={getUser.fbAuthURLLoading}
                  disabled={disabledOptions}
                  onClick={(e) => {
                    loadWalletMessageToSign();
                    if (process.env.REACT_APP_GA === "1") {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push(
                        prepareData({
                          event: "SignupWallet_clicked",
                        }),
                      );
                    }
                    if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("SignupWallet_clicked");
                    setTab(4);
                  }}
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  <img className="wallet-icon small-wallet-icon" src={wallet1} alt="" />
                  <h5>{onboardingType === "login" ? "Sign in with Wallet" : "Sign up with Wallet"}</h5>
                  <p>
                    {getUser.fbAuthURLLoading
                      ? "Authenticating with Wallet"
                      : onboardingType === "login"
                      ? "Existing Account"
                      : "Create Account"}
                  </p>
                </label>
                <img className="loading__icon fa-spin" src={loaderIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div
            className="fg-radio-group position-relative"
            onClick={(e) => {
              if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("SignupEmail_clicked");
              if (process.env.REACT_APP_GA === "1") {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(
                  prepareData({
                    event: "SignupEmail_clicked",
                  }),
                );
              }
              setTab(2);
            }}
          >
            <div className="form-check-group-outer">
              <div className="form-check position-relative d-flex">
                <input
                  className="form-check-input"
                  type="radio"
                  name="walletRadio"
                  id="flexRadioDefault3"
                  disabled={disabledOptions}
                />
                <label className="form-check-label" for="flexRadioDefault3">
                  <img className="wallet-icon small-wallet-icon" src={emailIcon} alt="" />
                  <h5>{onboardingType === "login" ? "Sign in with Email" : "Sign up with Email"}</h5>
                  <p>{onboardingType === "login" ? "Existing Account" : "Create Account"}</p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ErrorPopup errorTitle={showErrorTitle} error={showError} setError={setShowError} title={`Error`} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadGoogleAuthURL: (callback) => dispatch(loadGoogleAuthURL(callback)),
  authorizeGoogleCode: (data, callback) => dispatch(authorizeGoogleCode(data, callback)),
  toggleGoogleAuthUrlLoading: (data) => dispatch(toggleGoogleAuthUrlLoading(data)),
  loadFBAuthURL: (callback) => dispatch(loadFBAuthURL(callback)),
  authorizeFBCode: (data, callback) => dispatch(authorizeFBCode(data, callback)),
  toggleFBAuthUrlLoading: (data) => dispatch(toggleFBAuthUrlLoading(data)),

  loadDiscordAuthURL: (callback) => dispatch(loadDiscordAuthURL(callback)),
  authorizeDiscordCode: (data, callback) => dispatch(authorizeDiscordCode(data, callback)),
  toggleDiscordAuthUrlLoading: (data) => dispatch(toggleDiscordAuthUrlLoading(data)),

  toggleHTPRedux: (data) => dispatch(toggleHTPRedux(data)),
  loadProfile: (callback) => dispatch(loadProfile(callback)),
  loadBountyBoxes: (callback) => dispatch(loadBountyBoxes(callback)),
  loadWalletMessageToSign: () => dispatch(loadWalletMessageToSign()),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingOptions);
