import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OnboardingModal from "common/modals/onboarding/onboarding";
//images
const signupBannerImg = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/free-coins-img.png";
const createAccountImg = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/add-friend-icon.svg";
const lootImg = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/loot-icon-logo.svg";
const prizesImg = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/ticket-logo.svg";

const NonLoggedInPlaceholder = () => {
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [onboardingType, setOnboardingType] = useState("login");
  const navigate = useNavigate();
  return (
    <>
      <div className="not-logged-wrap w-100 h-100 d-flex flex-column">
        <div className="nlw-image-box-holder">
          <div className="nlw-image-box">
            <img src={signupBannerImg} width={429} height={465} alt="not logged img" />
          </div>
        </div>
        <div className="nlw-text-box">
          <div
            className="nlw-text-row d-flex flex-wrap align-items-center pointer"
            onClick={() => {
              setOnboardingType("signup");
              setShowSignupModal(true);
            }}
          >
            <div className="nlw-text-img">
              <img src={createAccountImg} width={38} height={38} alt="" />
            </div>
            <div className="nlw-text">
              <h4>Create Account</h4>
              <p>Bonus for new users only</p>
            </div>
          </div>
          <div
            className="nlw-text-row d-flex flex-wrap align-items-center pointer"
            onClick={() => {
              navigate("/contests");
            }}
          >
            <div className="nlw-text-img">
              <img src={lootImg} width={38} height={40} alt="" />
            </div>
            <div className="nlw-text">
              <h4>Enter Loot Contests</h4>
              <p>Win tickets from your rank</p>
            </div>
          </div>
          {/* <div
            className="nlw-text-row d-flex flex-wrap align-items-center pointer"
            onClick={() => {
              navigate("/prizes");
            }}
          >
            <div className="nlw-text-img">
              <img src={prizesImg} width={34} height={27} alt="" />
            </div>
            <div className="nlw-text">
              <h4>Win Sweepstakes Prizes</h4>
              <p>Enter tickets for a shot to win</p>
            </div>
          </div> */}
        </div>
        <div className="fs-btn-wrap d-flex flex-column mt-auto">
          <button
            className="default-orange-btn btn-block border-0 common-btn-gradient"
            onClick={() => {
              setOnboardingType("signup");
              setShowSignupModal(true);
            }}
          >
            Sign Up
          </button>
          <button
            className="btn btn-light-gray btn-block"
            onClick={() => {
              setOnboardingType("login");
              setShowSignupModal(true);
            }}
          >
            SIGN IN
          </button>
        </div>
      </div>
      <OnboardingModal
        showModal={showSignupModal}
        toggleModal={() => setShowSignupModal(false)}
        onboardingType={onboardingType}
      />
    </>
  );
};

export default NonLoggedInPlaceholder;
