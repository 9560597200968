import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Slider from "react-slick";
import { getCookie } from "utils/cookiesUtilities";

//  Images

import { prepareData } from "common/gtmDatalayer";
import { contestEntry, getUser, loadProfile } from "store/user";
import { withRouter } from "utils/withRouter";

const HowItWorks = ({ showModal, toggleModal, bgGame }) => {
  const themeMode = getCookie("themeMode");
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  useEffect(() => {
    if (showModal) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(
        prepareData({
          event: "how_to_play", // fixed value
          section: "carousel", // fixed value
          advanmcement: "button", // dynamic value
          step: "1", // dynamic value
        }),
      );
    }
  }, [showModal]);

  const options1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    dots: true,
    adaptiveHeight: true,
    asNavFor: ".tuts-text-slider",
  };
  const options2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: ".tuts-thumbnail-slider",
    dots: false,
    arrows: false,
    adaptiveHeight: true,
  };
  const { description, desktopImage } = bgGame;
  return (
    <>
      <Modal
        className={`modal fade hiw-modal custom-modal ${themeMode === "dark" && "dark-modal"}`}
        dialogClassName="modal-dialog modal-dialog-centered modal-sm"
        show={showModal}
        onHide={toggleModal}
        id={themeMode === "dark" ? "allConteszt" : "allConteszt"}
        tabindex="-1"
      >
        <div className="tutorial-content-box text-center">
          <Slider
            ref={(slider) => setNav1(slider)}
            className="pb-0 tuts-thumbnail-slider w-100"
            {...options1}
            asNavFor={nav2}
            key="hiw-slider-3"
          >
            <div>
              <div className="tuts-thumbnail w-100">
                <img src={process.env.REACT_APP_CDNURL + desktopImage?.large} alt="" />
              </div>
            </div>
          </Slider>
          <div className="" style={{ padding: "40px 0" }}>
            <Slider
              ref={(slider) => setNav2(slider)}
              {...options2}
              asNavFor={nav1}
              key="hiw-slider-4"
              className="pb-0 tuts-text-slider w-100"
            >
              <div>
                <h3>How It Works</h3>
                <p className="para-line">{description}</p>

                <a
                  href="#!"
                  className="btn btn-dark-black common-btn-gradient mt-2"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal(e);
                  }}
                >
                  DISMISS
                </a>
              </div>
            </Slider>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  contestEntry: (data, callback) => dispatch(contestEntry(data, callback)),
  loadProfile: () => dispatch(loadProfile()),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HowItWorks));
