import React from "react";
const arrowIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/s-chevron-left-arrow.svg";

function DetailsModalCard({ title, subTitle, icon, onClick }) {
  return (
    <div className="grow-flex-grow-item pointer " style={{ height: 84 }} onClick={onClick}>
      <div className="grow-flex-grow-box w-100 h-100 d-flex flex-wrap align-items-center">
        <div className="grow-flex-grow-icon d-flex align-items-center justify-content-center h-100">
          <img src={icon} alt="" />
        </div>
        <div className="grow-flex-grow-text">
          <h5>{title}</h5>
          <p>{subTitle}</p>
        </div>
        <button type="button" className="grow-flex-grow-btn d-flex align-items-center justify-content-center">
          <img src={arrowIcon} alt="" />
        </button>
      </div>
    </div>
  );
}

export default DetailsModalCard;
