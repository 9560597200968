export const verisoulLogin = (userName) => {
  if (window?.Verisoul)
    window?.Verisoul?.account({
      id: userName,
    });
};
export const verisoulSessionId = async () => {
  if (!window?.Verisoul) {
    return;
  }
  try {
    const { session_id } = await window?.Verisoul?.session();
    return session_id;
  } catch (e) {}
};
export const verisoulLogout = async () => {
  if (!window?.Verisoul) {
    return;
  }
  try {
    await window?.Verisoul?.reinitialize();
  } catch (e) {}
};
