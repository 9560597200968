import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
  checkEmail,
  checkUsername,
  contestEntry,
  getUser,
  loadBountyBoxes,
  loadProfile,
  loginUser,
  registerUser,
  resendOTP,
  resetPassword,
  toggleDiscordModalRedux,
  toggleHowToPlayRedux,
  toggleHTPRedux,
  togglePopupRedux,
  updateImage,
  verifyOTP,
} from "store/user";
import { withRouter } from "utils/withRouter";

//  Components
import { useSearchParams } from "react-router-dom";
import FreeCredits from "./freeCredits";
import OnboardingEmail from "./onboardingEmail";
import OnboardingOptions from "./onboardingOptions";
import UpdateUsername from "./updateUsername";
import WalletLogin from "./walletLogin";

const HowItWorks = ({
  showModal,
  toggleModal,
  getUser,
  loginUser,
  registerUser,
  verifyOTP,
  loadProfile,
  updateImage,
  checkEmail,
  checkUsername,
  togglePopupRedux,
  toggleHowToPlayRedux,
  toggleHTPRedux,
  resendOTP,
  resetPassword,
  toggleDiscordModalRedux,
  onboardingType = "login",
  emptyProfiledata,
}) => {
  const [tab, setTab] = useState(1);
  const [optionsType, setOptionsType] = useState(onboardingType);
  const [walletDetails, setWalletDetails] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const loginEmailProps = {
    showModal,
    toggleModal,
    getUser,
    loginUser,
    registerUser,
    verifyOTP,
    loadProfile,
    updateImage,
    checkEmail,
    checkUsername,
    togglePopupRedux,
    toggleHowToPlayRedux,
    toggleHTPRedux,
    resendOTP,
    resetPassword,
    toggleDiscordModalRedux,
    onboardingType: optionsType,
  };
  const [newAcc, setNewAcc] = useState(false);

  useEffect(() => {
    if (!showModal) {
      setTab(1);
    } else {
      setOptionsType(onboardingType);
    }
    const loginParam = searchParams.get("login");
    const registerParam = searchParams.get("register");
    if (loginParam) {
      searchParams.delete("login");
    }
    if (registerParam) {
      searchParams.delete("register");
    }
    if (loginParam || registerParam) setSearchParams(searchParams, { replace: true });
  }, [showModal]);

  useEffect(() => {
    if (emptyProfiledata) setTab(3);
  }, [emptyProfiledata]);
  return (
    <Modal
      className={`modal left fade custom-modal dark-modal ${tab === 5 && "venly-modal"}`}
      centered
      contentClassName="justify-content-center align-items-center"
      show={showModal}
      onHide={null}
      id={tab === 5 ? "accountCreatedModal" : "createAccountModal"}
      tabindex="-1"
    >
      {tab === 1 ? (
        <OnboardingOptions
          setTab={setTab}
          toggleModal={toggleModal}
          setNewAcc={setNewAcc}
          onboardingType={optionsType}
        />
      ) : tab === 2 ? (
        <OnboardingEmail {...loginEmailProps} setTab={setTab} walletDetails={walletDetails} />
      ) : tab === 3 ? (
        <UpdateUsername toggleModal={toggleModal} newAcc={newAcc} setTab={setTab} setOptionsType={setOptionsType} />
      ) : tab === 4 ? (
        <WalletLogin
          toggleModal={toggleModal}
          newAcc={newAcc}
          setTab={setTab}
          setOptionsType={setOptionsType}
          setWalletDetails={setWalletDetails}
        />
      ) : (
        <FreeCredits toggleModal={toggleModal} />
      )}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  contestEntry: (data, callback) => dispatch(contestEntry(data, callback)),
  loadProfile: (callback) => dispatch(loadProfile(callback)),
  loginUser: (data, callback) => dispatch(loginUser(data, callback)),
  verifyOTP: (data, callback) => dispatch(verifyOTP(data, callback)),
  checkUsername: (data, callback) => dispatch(checkUsername(data, callback)),
  checkEmail: (data, callback) => dispatch(checkEmail(data, callback)),
  updateImage: (data, callback) => dispatch(updateImage(data, callback)),
  registerUser: (data, callback) => dispatch(registerUser(data, callback)),
  resendOTP: (data, callback) => dispatch(resendOTP(data, callback)),
  resetPassword: (data, callback) => dispatch(resetPassword(data, callback)),
  togglePopupRedux: (data) => dispatch(togglePopupRedux(data)),
  toggleHTPRedux: (data) => dispatch(toggleHTPRedux(data)),
  toggleHowToPlayRedux: (data) => dispatch(toggleHowToPlayRedux(data)),
  toggleDiscordModalRedux: (data) => dispatch(toggleDiscordModalRedux(data)),
  loadBountyBoxes: (callback) => dispatch(loadBountyBoxes(callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HowItWorks));
