import React from "react";

//Images
const statusIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/warning.svg";
const AlertError = (props) => {
  return (
    <>
      <div className="alert-icon">
        <img src={statusIcon} alt="" />
      </div>
      <div className="mx-5 alert-text">
        {typeof props.message === "string" ? props.message : "Something went wrong"}
      </div>
    </>
  );
};

export default AlertError;
