import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { initializeConnector } from "@web3-react/core";

export const [coinbaseWallet, coinbaseWalletHooks] = initializeConnector(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
        appName: "web3-react-demo",
      },
    }),
);
