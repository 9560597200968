const prepareData = (data) => {
  const defaultData = {
    event: undefined,
    section: undefined,
    click_text: undefined,
    game_name: undefined,
    interaction_type: undefined,
    advanmcement: undefined,
    step: undefined,
    lead_type: undefined,
    social_media: undefined,
  };
  const dataLayer = {};
  Object.keys(defaultData).forEach((key) => {
    if (data[key]) {
      dataLayer[key] = data[key];
    } else {
      dataLayer[key] = defaultData[key];
    }
  });

  return dataLayer;
};

exports.prepareData = prepareData;
