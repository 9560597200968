import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

const DateDropDown = ({ onChangeDate }) => {
  const dayOptions = Array(31)
    .fill()
    .map((_, i) => ({ name: (i + 1).toString(), value: i + 1 }));
  const monthOptions = [
    { name: "Jan", value: 1 },
    { name: "Feb", value: 2 },
    { name: "Mar", value: 3 },
    { name: "Apr", value: 4 },
    { name: "May", value: 5 },
    { name: "Jun", value: 6 },
    { name: "Jul", value: 7 },
    { name: "Aug", value: 8 },
    { name: "Sep", value: 9 },
    { name: "Oct", value: 10 },
    { name: "Nov", value: 11 },
    { name: "Dec", value: 12 },
  ];
  const generateArrayOfYears = () => {
    const max = new Date().getFullYear();
    const min = max - 100;
    const years = [];

    for (var i = max; i >= min; i--) {
      years.push({ name: i, value: i });
    }
    return years;
  };
  const [day, setDay] = useState({});
  const [month, setMonth] = useState({});
  const [year, setYear] = useState({});

  const onChangeDay = ({ value, name }) => {
    setDay({ value, name });
    onChangeDate({ target: { name: "date", value: { years: year?.value, months: month?.value - 1, days: value } } });
  };
  const lastDayOfMonth = (month, year) => {
    const date = new Date();
    let d = 31;
    if (!isNaN(month)) {
      if (isNaN(year)) d = new Date(date.getFullYear(), parseInt(month), 0).getDate();
      else d = new Date(parseInt(year), parseInt(month), 0).getDate();
    }
    return d;
  };
  const lastMonthOfYear = (year) => {
    const date = new Date();
    let d = 12;

    if (!isNaN(year) && date.getFullYear() === year) d = date.getMonth() + 1;

    return d;
  };
  const onChangeMonth = ({ value, name }) => {
    const lastDay = lastDayOfMonth(value, year?.value);
    if (!isNaN(day?.value) && lastDay < day?.value) setDay({});
    setMonth({ value, name });
    onChangeDate({ target: { name: "date", value: { years: year?.value, months: value - 1, days: day?.value } } });
  };
  const onChangeYear = ({ value, name }) => {
    const lastDay = lastDayOfMonth(month?.value, value);
    if (!isNaN(day?.value) && lastDay < day?.value) setDay({});
    const lastMonth = lastMonthOfYear(value);
    if (!isNaN(month?.value) && lastMonth < month?.value) setMonth({});
    setYear({ value, name });
    onChangeDate({ target: { name: "date", value: { years: value, months: month?.value - 1, days: day?.value } } });
  };
  return (
    <div className="form-select-group mt-3 d-flex flex-wrap align-items-center gap-3">
      <div className="form-select-group-item flex-grow-1">
        <div className="form-group-input w-100 h-100">
          <Dropdown>
            <Dropdown.Toggle as={"div"} className="form-select d-flex align-items-center">
              <span>{day?.name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="date-menu">
              {dayOptions.slice(0, lastDayOfMonth(month?.value, year?.value)).map(({ value, name }) => (
                <Dropdown.Item onClick={() => onChangeDay({ value, name })}>{name}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="form-select-group-item flex-grow-1">
        <div className="form-group-input w-100 h-100">
          <Dropdown>
            <Dropdown.Toggle as={"div"} className="form-select d-flex align-items-center">
              {month?.name}
            </Dropdown.Toggle>
            <Dropdown.Menu className="date-menu">
              {monthOptions?.slice(0, lastMonthOfYear(year?.value)).map(({ value, name }) => (
                <Dropdown.Item onClick={() => onChangeMonth({ value, name })}>{name}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="form-select-group-item flex-grow-1">
        <div className="form-group-input w-100 h-100">
          <Dropdown>
            <Dropdown.Toggle as={"div"} className="form-select d-flex align-items-center">
              {year?.name}
            </Dropdown.Toggle>
            <Dropdown.Menu className="date-menu">
              {generateArrayOfYears().map(({ value, name }) => (
                <Dropdown.Item onClick={() => onChangeYear({ value, name })}>{name}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default DateDropDown;
