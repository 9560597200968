import { Link } from "react-router-dom";

const Button = ({ classes, onClick, title, icon, href, target, iconLeft, iconWidth, iconHeight }) => {
  return (
    <Link
      to={href ?? "#!"}
      className={classes}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      target={target ?? "_self"}
    >
      {iconLeft && (
        <span className="btn-icon-box-right d-flex align-items-center ">
          <img src={iconLeft} alt="" />
        </span>
      )}
      {title}
      {icon && (
        <span className="btn-icon-box d-flex align-items-center">
          <img src={icon} width={iconWidth} height={iconHeight} alt="" />
        </span>
      )}
    </Link>
  );
};
export default Button;
