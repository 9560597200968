import * as React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { BrowserRouter } from "react-router-dom";

import "@fortawesome/fontawesome-svg-core/styles.css";
import "rc-slider/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import { injectStyle } from "react-toastify/dist/inject-style";

import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { setCookie } from "./utils/cookiesUtilities";
import AlertClose from "./common/alert/alertClose";
import { v4 as uuidv4 } from "uuid";

const container = document.getElementById("root");
const root = createRoot(container);
const store = configureStore();

const deviceId = localStorage.getItem("deviceId");
if (!deviceId) {
  localStorage.setItem("deviceId", uuidv4());
}
const themeMode = "dark";
setCookie("themeMode", themeMode);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// TOASTIFY inject call
injectStyle();
root.render(
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      closeOnClick
      rtl={false}
      toastClassName={`alert d-flex align-items-center ${themeMode === "dark" && "dark-toast-bg"}`}
      draggable
      closeButton={<AlertClose />}
      pauseOnHover
    />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
