import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import {
  checkEmail,
  checkUsername,
  contestEntry,
  getUser,
  loadBountyBoxes,
  loadProfile,
  loginUser,
  registerUser,
  resendOTP,
  resetPassword,
  toggleDiscordModalRedux,
  toggleHowToPlayRedux,
  toggleHTPRedux,
  togglePopupRedux,
  updateImage,
  verifyOTP,
} from "store/user";
import { withRouter } from "utils/withRouter";

//  Images
const creditsImage = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/loot-arcade-winner-img.png";
const lootIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/loot-logo-large.svg";
const arcadeIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/ticketLogo-2.svg";

const FreeCredits = ({ toggleModal, getUser, toggleHowToPlayRedux }) => {
  const userProfile = getUser.profile?.userProfile;
  return (
    <>
      <div className="content-modal-box hide-scrollbar position-relative text-center">
        <div className="anm-row-header text-center">
          <h5>ACCOUNT CREATED</h5>
        </div>
        <div className="anm-row-body">
          <div className="heading-wrap text-center">
            <h5>PLAY ON US!</h5>
            <h6>FREE ARCADE COINS & LOOT BUCKS DELIVERED.</h6>
          </div>
          <div className="price-awarded-block">
            <div className="pab-logo-area">
              <div className="pab-logo">
                <img src={creditsImage} alt="ticketWinner" />
              </div>
            </div>
            <div className="pab-text-area d-flex flex-nowrap justify-content-center">
              <div className="ticket-item">
                {getUser.loading ? (
                  <Skeleton height={70} borderRadius={9} />
                ) : (
                  <div className="ticket w-100 h-100 d-flex flex-nowrap align-items-center justify-content-center arcade-award">
                    <div className="ticket-icon-box">
                      <img src={arcadeIcon} alt="ticketLogo2" />
                    </div>
                    <div className="ticket-text-box align-self-center">
                      <div className="ticket-label">{userProfile?.arcadeCoins?.toLocaleString("en-us") ?? 0}</div>
                      <div className="ticket-txt">ARCADE COINS</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="ticket-item">
                {getUser.loading ? (
                  <Skeleton height={70} borderRadius={9} />
                ) : (
                  <div className="ticket w-100 h-100 d-flex flex-nowrap align-items-center justify-content-center ticket-award">
                    <div className="ticket-icon-box">
                      <img src={lootIcon} alt="ticketLogo1" />
                    </div>
                    <div className="ticket-text-box align-self-center">
                      <div className="ticket-label orange-txt">
                        {userProfile?.lootBonusCoins?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) ?? 0}
                      </div>
                      <div className="ticket-txt">FREE LOOT Bucks</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pab-txt-box">
            <p>
              Now it’s time to show the community what you’ve got! Enter contests to rank the leaderboard & win prizes.
            </p>
          </div>
        </div>
        <div className="form-group-button mt-0" style={{ marginTop: "0px !important" }}>
          <button
            type="submit"
            className={`btn btn-gradient-default btn-block common-btn-gradient`}
            onClick={(e) => {
              e.preventDefault();
              toggleHowToPlayRedux(true);
              // togglePopupRedux(true);
              toggleModal();
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  contestEntry: (data, callback) => dispatch(contestEntry(data, callback)),
  loadProfile: (callback) => dispatch(loadProfile(callback)),
  loginUser: (data, callback) => dispatch(loginUser(data, callback)),
  verifyOTP: (data, callback) => dispatch(verifyOTP(data, callback)),
  checkUsername: (data, callback) => dispatch(checkUsername(data, callback)),
  checkEmail: (data, callback) => dispatch(checkEmail(data, callback)),
  updateImage: (data, callback) => dispatch(updateImage(data, callback)),
  registerUser: (data, callback) => dispatch(registerUser(data, callback)),
  resendOTP: (data, callback) => dispatch(resendOTP(data, callback)),
  resetPassword: (data, callback) => dispatch(resetPassword(data, callback)),
  togglePopupRedux: (data) => dispatch(togglePopupRedux(data)),
  toggleHTPRedux: (data) => dispatch(toggleHTPRedux(data)),
  toggleHowToPlayRedux: (data) => dispatch(toggleHowToPlayRedux(data)),
  toggleDiscordModalRedux: (data) => dispatch(toggleDiscordModalRedux(data)),
  loadBountyBoxes: (callback) => dispatch(loadBountyBoxes(callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FreeCredits));
