import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "lootbox",
  initialState: {
    lootboxes: [],
    lootbox: {},
    lastFetchLootboxes: null,
    spinPrize: {},
    loading: false,
    lootboxLoading: false,
    spinLootboxLoading: false,
  },

  reducers: {
    lootboxesRequested: (misc, action) => {
      misc.loading = true;
    },
    lootboxesReceived: (misc, action) => {
      misc.lootboxes = action.payload.data;
      misc.lastFetchLootboxes = Date.now();
      misc.loading = false;
    },
    lootboxesRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    lootboxRequested: (misc, action) => {
      misc.lootboxLoading = true;
    },
    lootboxReceived: (misc, action) => {
      misc.lootbox = action.payload.data;
      misc.lootbox.lootBoxPrizes = action.payload.data?.lootBoxPrizes?.filter((prize) => !prize?.isLimitReached);
      misc.lastFetch = Date.now();
      misc.lootboxLoading = false;
    },
    lootboxRequestFailed: (misc, action) => {
      misc.lootboxLoading = false;
    },
    spinLootboxRequested: (misc, action) => {
      misc.spinLootboxLoading = true;
      misc.spinPrize = {}; //reset spin prize data on every spin
    },
    spinLootboxReceived: (misc, action) => {
      misc.spinPrize = action.payload.data;
      misc.lastFetch = Date.now();
      misc.spinLootboxLoading = false;
    },
    spinLootboxRequestFailed: (misc, action) => {
      misc.spinLootboxLoading = false;
    },
  },
});

export const {
  lootboxesRequested,
  lootboxesReceived,
  lootboxesRequestFailed,
  lootboxRequested,
  lootboxReceived,
  lootboxRequestFailed,
  spinLootboxRequested,
  spinLootboxReceived,
  spinLootboxRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const lootboxUrl = "lootBox/";

export const loadLootboxes = (params, callback) => (dispatch, getState) => {
  const { lastFetchLootboxes } = getState().entities.lootbox;
  const diffInMinutes = moment().diff(moment(lastFetchLootboxes), "minutes");
  if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: lootboxUrl,
      params,
      onStart: lootboxesRequested.type,
      onSuccess: lootboxesReceived.type,
      onError: lootboxesRequestFailed.type,
      callback,
    }),
  );
};
export const loadLootbox = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl + id,
      onStart: lootboxRequested.type,
      onSuccess: lootboxReceived.type,
      onError: lootboxRequestFailed.type,
      callback,
    }),
  );
};
export const spinLootbox = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: lootboxUrl + "spin/" + id,
      method: "POST",
      data,
      onStart: spinLootboxRequested.type,
      onSuccess: spinLootboxReceived.type,
      onError: spinLootboxRequestFailed.type,
      callback,
    }),
  );
};
export const getLootbox = createSelector(
  (state) => state.entities.lootbox,
  (lootbox) => lootbox,
);
