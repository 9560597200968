import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { getCookie } from "../utils/cookiesUtilities";
import { getUser, togglePopupRedux, toggleConnectWalletRedux } from "../store/user";
import { connect } from "react-redux";

import HtmlParser from "react-html-parser";
import { withRouter } from "../utils/withRouter";

const errorIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/reporticon-1.png";
const closeIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/close-icon.svg";

class ErrorPopup extends Component {
  state = {};
  render() {
    const themeMode = getCookie("themeMode");
    const { getUser, togglePopupRedux, toggleConnectWalletRedux } = this.props;
    return (
      <Modal
        className={`modal fade custom-modal ${themeMode === "dark" && "dark-modal"}`}
        dialogClassName="modal-dialog modal-dialog-centered modal-sm"
        show={this.props.error}
        onHide={() => this.props.setError(false)}
        id="darkerrorModal"
      >
        <div className="modal-content-box position-relative text-center">
          <div
            className="modal-close position-absolute"
            data-bs-dismiss="modal"
            onClick={() => {
              if (
                this.props.errorType &&
                (this.props.errorType === "Invalid Contest" || this.props.errorType === "This contest has closed")
              ) {
                this.props.router.navigate("/");
              }
              this.props.setError("");
            }}
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className="modal-content-header">
            <div className="content-header-title">
              {this.props.errorTitle ? this.props.errorTitle.toUpperCase() : "Something went wrong"}
            </div>
          </div>
          <div className="modal-content-body">
            <div className="modal-content-wrapper p-0">
              <div className="logo-modal">
                <img src={errorIcon} alt="" />
              </div>

              <div className="error-modal-content mw-unset ">
                <p className="para-line">{HtmlParser(this.props.error)}</p>
              </div>

              <div className="modal-button-row">
                <a
                  href="#!"
                  className="rounded-pill gradient-button-btn common-btn-gradient"
                  onClick={(e) => {
                    e.preventDefault();
                    if (getUser.showConnectWalletAfter) {
                      togglePopupRedux(true);
                      toggleConnectWalletRedux(false);
                    }
                    if (
                      this.props.errorType &&
                      (this.props.errorType === "Invalid Contest" || this.props.errorType === "This contest has closed")
                    ) {
                      this.props.router.navigate("/contests");
                    }
                    this.props.setError("");
                  }}
                >
                  {this.props.errorType === "Invalid Contest" || this.props.errorType === "This contest has closed"
                    ? "BROWSE CONTESTS"
                    : getUser.showConnectWalletAfter
                    ? "CONNECT WALLET"
                    : "DISMISS"}
                </a>
                {getUser.showConnectWalletAfter && (
                  <a
                    href="#!"
                    className={`btn btn-label-btn my-0`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (getUser.showConnectWalletAfter) {
                        toggleConnectWalletRedux(false);
                      }
                      this.props.setError("");
                    }}
                  >
                    DISMISS
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  togglePopupRedux: (payload) => dispatch(togglePopupRedux(payload)),
  toggleConnectWalletRedux: (payload) => dispatch(toggleConnectWalletRedux(payload)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorPopup));
