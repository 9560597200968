import { useWeb3React } from "@web3-react/core";
import ErrorPopup from "common/errorPopup";
import { prepareData } from "common/gtmDatalayer";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import {
  getUser,
  loadBountyBoxes,
  loadMessageToSign,
  loadProfile,
  toggleDiscordModalRedux,
  walletLogin,
} from "store/user";
import { setToken } from "utils/localStorageServices";
import { withRouter } from "utils/withRouter";
import { connectors } from "web3/connectors";
import { coinbaseWallet } from "web3/connectors/coinbase";
import { metaMask } from "web3/connectors/metaMask";

//  Images
const logo = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/bgNewLogo.svg";
const greenIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/green-check-icon.svg";
const loaderIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/loader-svg.svg";
const wallet1 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/t-mode-2.svg";
const wallet2 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/wallet-type-2.png";
const wallet3 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/wallet-type-3.png";

const WalletLogin = (props) => {
  // const { setErrors, setGenesisError } = props || {};
  const [errors, setErrors] = useState();
  const [signMessage, setSignMessage] = React.useState(true);
  const [signature, setSignature] = React.useState("");
  const [loadingWallet, setLoadingWallet] = React.useState("");
  const [connectedWallet, setConnectedWallet] = React.useState("");
  const { account, isActive, connector } = useWeb3React();
  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };
  const deactivate = () => {
    if (connector.deactivate) {
      connector.deactivate();
    }
    connector?.resetState();
  };

  //   Get signatures for different wallet connections
  useEffect(() => {
    if (account) {
      async function getSignature() {
        if (!signMessage) await signMessages();
      }
      getSignature();
    }
  }, [isActive]);

  //  Get Messages to sign transaction
  useEffect(() => {
    clearData();
  }, []);

  //  Reset states on modal close
  const clearData = () => {
    setSignature("");
    setLoadingWallet("");
    setConnectedWallet("");
    setSignMessage(false);
  };

  //  Connect wallet to the user account after signing the transaction
  const signMessages = async () => {
    // If metamask extension not found, show error
    if (!connector) return;
    if (!props.getUser.messageToSign) {
      return setErrors("Something went wrong. Please try again later.");
    }
    try {
      // Sign message
      const signatureStr = props.getUser.messageToSign;
      const signature = await connector?.provider?.request({
        method: "personal_sign",
        params: [signatureStr, account],
      });
      setSignature(signature);
      setConnectedWallet(loadingWallet);
      props.setWalletDetails({ signature, walletAddress: account, message: signatureStr });
      //  Login wallet API call
      props.walletLogin({ signature, walletAddress: account, message: signatureStr }, (res) => {
        if (res.status === 200) {
          if (process.env.REACT_APP_MIXPANEL == 1) mixpanel.track("Wallet Login Completed");
          if (process.env.REACT_APP_GA == 1) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(
              prepareData({
                event: "Wallet Login Completed",
              }),
            );
          }
          props.toggleModal();

          localStorage.setItem("userProfile", JSON.stringify(res.data.userProfile));

          if (process.env.REACT_APP_MIXPANEL === "1") {
            mixpanel?.track("Login", { Type: "email" });
            mixpanel.identify(res?.data?.userProfile?.id);
          }
          setToken(false, res.data.jwtToken);
          props.loadProfile((res) => {
            if (res?.data?.connectedWallet?.walletAddress) {
              props.loadBountyBoxes();
            }
          });
          window.innerWidth > 1280.98 && toggleDiscordModalRedux(true);
        } else {
          props.setOptionsType("signup");
          props.setTab(2);
        }
      });
    } catch (error) {
      setLoadingWallet("");
      setErrors(error?.message);
      deactivate();
    }
  };

  // Connect wallet to the user account after signing the transaction
  const activating = async (params) => {
    if (process.env.REACT_APP_MIXPANEL == 1) mixpanel?.track("Wallet Connection Initiated");
    if (process.env.REACT_APP_GA == 1) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(
        prepareData({
          event: "Wallet Connection Initiated",
        }),
      );
    }
    try {
      const connectorWallet = {
        injected: metaMask,
        coinbaseWallet: coinbaseWallet,
      };
      await connectorWallet[params]?.activate();
    } catch (error) {
      setLoadingWallet("");
      setErrors(error?.message);
    }
  };
  return (
    <>
      {errors && (
        <ErrorPopup error={errors} setError={setErrors} title={`<div className="cm-text">Something went wrong</div>`} />
      )}
      <div className="content-modal-box hide-scrollbar position-relative text-center m-0">
        <div className="content-modal-header">
          <img className="content-modal-logo" src={logo} alt="" />
          <h5>Connect Wallet</h5>
        </div>

        {props.getUser.messageToSignLoading ? (
          <div className="form-group loading-wallets ">
            <Skeleton style={{ marginBottom: 30 }} />
            <Skeleton style={{ marginBottom: 30 }} />
          </div>
        ) : (
          <>
            <div className="form-group">
              <div
                className="fg-radio-group position-relative"
                onClick={(e) => {
                  if (connectedWallet) return;
                  e.preventDefault();
                  if (!window?.ethereum?.isMetaMask)
                    return window.open("https://metamask.app.link/dapp/" + process.env.REACT_APP_SITE_URL, "_blank");
                  setSignMessage(false);
                  activating("injected");
                  setProvider("injected");
                  setLoadingWallet("injected");
                }}
              >
                <div className="form-check-group-outer">
                  <div className="form-check position-relative d-flex">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="walletRadio1"
                      key={loadingWallet + "1"}
                      id="flexRadioDefault1"
                      checked={loadingWallet === "injected" || connectedWallet === "injected"}
                    />
                    <label className="form-check-label wallet-label" for="flexRadioDefault1">
                      <img className="wallet-icon" src={wallet1} alt="" />
                      <h5>Metamask</h5>
                      <p>{connectedWallet === "injected" ? "Connected" : "Click to connect"}</p>
                    </label>

                    {connectedWallet === "injected" ? (
                      <img className="wallet-connect-icon d-block" src={greenIcon} alt="" />
                    ) : (
                      <img className="loading__icon top-32 fa-spin" src={loaderIcon} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div
                className="fg-radio-group position-relative"
                onClick={(e) => {
                  e.preventDefault();
                  if (connectedWallet) return;
                  setLoadingWallet("coinbaseWallet");
                  setSignMessage(false);
                  activating("coinbaseWallet");
                  setProvider("coinbaseWallet");
                }}
              >
                <div className="form-check-group-outer">
                  <div className="form-check position-relative d-flex">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="walletRadio2"
                      key={loadingWallet + "2"}
                      id="flexRadioDefault2"
                      checked={loadingWallet === "coinbaseWallet" || connectedWallet === "coinbaseWallet"}
                    />
                    <label className="form-check-label wallet-label" for="flexRadioDefault2">
                      <img className="wallet-icon" src={wallet2} alt="" />
                      <h5>Coinbase Wallet</h5>
                      <p>{connectedWallet === "coinbaseWallet" ? "Connected" : "Click to connect"}</p>
                    </label>
                    {connectedWallet === "coinbaseWallet" ? (
                      <img className="wallet-connect-icon d-block" src={greenIcon} alt="" />
                    ) : (
                      <img className="loading__icon top-32 fa-spin" src={loaderIcon} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <div
                className="fg-radio-group position-relative"
                onClick={(e) => {
                  e.preventDefault();
                  if (connectedWallet) return;
                  setLoadingWallet("walletConnect");
                  setSignMessage(false);
                  activating("walletConnect");
                  setProvider("walletConnect");
                }}
              >
                <div className="form-check-group-outer">
                  <div className="form-check position-relative d-flex">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="walletRadio3"
                      key={loadingWallet + "3"}
                      id="flexRadioDefault3"
                      checked={loadingWallet === "walletConnect" || connectedWallet === "walletConnect"}
                    />
                    <label className="form-check-label wallet-label" for="flexRadioDefault3">
                      <img className="wallet-icon" src={wallet3} alt="" />
                      <h5>Wallet Connect</h5>
                      <p>{connectedWallet === "walletConnect" ? "Connected" : "Click to connect"}</p>
                    </label>
                    {connectedWallet === "walletConnect" ? (
                      <img className="wallet-connect-icon d-block" src={greenIcon} alt="" />
                    ) : (
                      <img className="loading__icon top-32 fa-spin" src={loaderIcon} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  walletLogin: (data, callback) => dispatch(walletLogin(data, callback)),
  loadProfile: () => dispatch(loadProfile()),
  loadBountyBoxes: (callback, hardRefresh) => dispatch(loadBountyBoxes(callback, hardRefresh)),
  loadMessageToSign: (callback) => dispatch(loadMessageToSign(callback)),
  toggleDiscordModalRedux: (data) => dispatch(toggleDiscordModalRedux(data)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WalletLogin));
