export const getNumberWithOrdinal = (n) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};

export const SWEEP_INSTANCES_STATUS = [
  "Inactive", // Sweepstake has not been activated yet
  "Active", // Sweepstake is open for users to enter
  "Paused", // Sweepstake is paused
  "Completed", // Sweepstake ended and winner's prize was paid out
  "Pending Chainlink", // Sweepstake ended and random number has been requested,
  "Pending Selecting Winner", // Random number has been fulfilled by ChainLink, ready to pick winner
  "Pending Setting Winner", // Winning ticket has been found, ready to set winner
  "Pending Transferring Prize", // Winning ticket has been found, ready to set winner
  "Canceled",
  "Expired",
];

export const SWEEP_INSTANCES_TEXTS = {
  0: { heading: "Sweepstake Paused", subText: "" },
  1: {
    heading: "PROCESSING TRANSACTION",
    subText: "THIS CAN TAKE UP TO A MINUTE.",
  },
  2: {
    heading: "Sweepstake Paused",
    subText: "",
    heading2: "Paused",
    subText2: "You can access when sweeps is active",
  },
  3: {
    heading: "WINNER SELECTED",
    subText: "PRIZE AWARDED",
    heading2: "Prize Awarded",
    subText2: "The chosen one is here!",
  },
  4: {
    heading: "WINNER SELECTION IN PROGRESS",
    subText: "",
    heading2: "Good Luck!",
    subText2: "Winner selection in progress...",
  },
  5: {
    heading: "WINNER SELECTION IN PROGRESS",
    subText: "",
    heading2: "Good Luck!",
    subText2: "Winner selection in progress...",
  },
  6: {
    heading: "WINNER SELECTION IN PROGRESS",
    subText: "",
    heading2: "Good Luck!",
    subText2: "Winner selection in progress...",
  },
  7: {
    heading: "PENDING REWARD TRANSFER",
    subText: "",
    heading2: "",
    subText2: "Sweepstake Winner Selected",
  },
  8: {
    heading: "Sweepstake Cancelled",
    subText: "",
    heading2: "Canceled",
    subText2: "Sweeps Canceled",
  },
  9: {
    heading: "WINNER SELECTION IN PROGRESS",
    subText: "",
    heading2: "Good Luck!",
    subText2: "Winner selection in progress...",
  },
};

//  Play Pixel Tracking method
export function loadJS(FILE_URL, async = true) {
  let scriptEle = document.createElement("script");

  scriptEle.setAttribute("src", FILE_URL);
  scriptEle.setAttribute("type", "text/javascript");
  scriptEle.setAttribute("async", async);

  document.body.appendChild(scriptEle);

  // success event
  scriptEle.addEventListener("load", () => {
    // console.log("File loaded");
  });
  // error event
  scriptEle.addEventListener("error", (ev) => {
    // console.log("Error on loading file", ev);
  });
}

export const SWEEPS_RECORDS_PER_PAGE = 4;
export const CONTESTWINNER_RECORDS_PER_PAGE = window.innerWidth < 767.98 ? 4 : 8;
export const SWEEPSRULES_RECORDS_PER_PAGE = window.innerWidth < 767.98 ? 4 : 8;

export const STRIP_APPEARANCE = {
  theme: "night",
  labels: "floating",
  variables: {
    borderRadius: "14px",
    colorText: "#989898",
    fontFamily: "proxima-nova, sans-serif",
    fontWeightBold: "500",
    colorBackground: "#0a0a0a",
    focusBoxShadow: "none",
    focusOutline: "#f0982c",
    colorPrimary: "#b25511",
    colorDanger: "#d42d2d",
    colorSuccess: "#369f15",
    colorTextSecondary: "#989898",
    iconColor: "#fff",
    gridRowSpacing: "30px",
    gridColumnSpacing: "21px",
  },
  rules: {
    ".Input": {
      borderColor: "#b25511",
      border: "2px solid #b25511",
    },
    ".Input::placeholder": {
      color: "#989898",
    },
    ".Input:focus": {
      borderColor: "#b25511",
      border: "2px solid #b25511",
    },
    ".Tab--selected": {
      backgroundColor: "none",
      outline: "none",
      border: "2px solid #b25511",
    },
    ".Tab:focus": {
      outline: "none",
      border: "2px solid #b25511",
      borderColor: "#b15310",
    },
    ".Tab": {
      outline: "none",
      border: "2px solid #b25511",
      borderColor: "#b15310",
    },
  },
};
